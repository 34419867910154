import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import '../submitStatus/dataSubmitStatus.css';
import api from "../../api";

function UserManagementDetail() {
    const { UserAuthSeq } = useParams();
    const [userDetail, setUserDetail] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const [listCount, setListCount] = useState(0); // 총 데이터 개수
    const [changedProjNos, setChangedProjNos] = useState([]); // 변경된 ProjNo 리스트
    const [btnPage, setBtnPage] = useState(1);

    const navigate = useNavigate();

    const pageSize = 10;
    const pageGroupSize = 10;

    const userDetailPages = async (page = 1) => {
        let endPoint = `/api/m/userProjAuthStatus?UserAuthSeq=${UserAuthSeq}&ps=${page}&pl=${pageSize}`;

        try {
            const res = await api.get(endPoint, {
                withCredentials: true,
            });
            setUserDetail(res.data.item);
            setListCount(res.data.pages.Total);
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize));

            const initialProjNos = res.data.item
                .filter((row) => row.AuthStatus === "Y")
                .map((row) => row.ProjNo);


            setChangedProjNos((prev) => [...new Set([...prev, ...initialProjNos])]);
        } catch (error) {
            console.error('API 에러 입니다.');
        }
    };

    useEffect(() => {
        userDetailPages(currentPage);
    }, [currentPage, UserAuthSeq]);

    const adminMenu = [
        {
            'id' : 1,
            'name': "캘린더(공개시기 관리)",
            'path': "/adminPage"
        },
        {
            'id' : 2,
            'name': "데이터 제출 현황",
            'path': "/adminPage/submintStatus"
        },
        {
            'id' : 3,
            'name': "데이터 제출 목록 일괄",
            'path': "/adminPage/dataSubmitList"
        },
        {
            'id' : 4,
            'name': "데이터 제출 상태 변경",
            'path': "/adminPage/dataSubmitStatusChange"
        },
        {
            'id' : 5,
            'name': "DMP 작성 현황 및 제출 상태 변경",
            'path': "/adminPage/dmpSubmitStatus"
        },
        // {
        //     'id' : 6,
        //     'name': "DMP 계획 및 제출 현황",
        //     'path': "/adminPage/dmpPlanStatus"
        // },
        {
            'id' : 6,
            'name': "데이터 관리 계획(User별)",
            'path': "/adminPage/userManagement"
        },
        {
            'id' : 7,
            'name': "데이터관리 계획 접근권한(과제별)",
            'path': "/adminPage/dmpAccessCheck"
        },
        {
            'id' : 8,
            'name': "생산계획 누락현황 확인",
            'path': "/adminPage/productionPlanMng"
        },
        {
            'id' : 9,
            'name': "신청서 관리 페이지",
            'path': "/adminPage/applicationManagement"
        },
    ]

    const handleAuthStatusChange = (index, newStatus) => {
        setUserDetail((prevData) =>
            prevData.map((row, i) => {
                if (i === index) {
                    if (newStatus === "Y" && !changedProjNos.includes(row.ProjNo)) {
                        setChangedProjNos((prev) => [...prev, row.ProjNo]);
                    } else if (newStatus === "N" && changedProjNos.includes(row.ProjNo)) {
                        setChangedProjNos((prev) => prev.filter((projNo) => projNo !== row.ProjNo));
                    }
                    return { ...row, AuthStatus: newStatus };
                }
                return row;
            })
        );
    };

    console.log("changedProjNos", changedProjNos);
    const authChangeHandler = async () => {

        const formattedProjNos = changedProjNos.map(projNo => `'${projNo}'`).join(",");
        try {
            await api.patch(`/api/m/userProjArr/${UserAuthSeq}/${formattedProjNos}`, {}, { withCredentials: true })
                .then(() => {
                    alert("권한 변경이 성공되었습니다.");
                    navigate(-1)
                });
        } catch (err) {
            console.error("API 에러:", err);
        }
    };

    const handerbtn = (pageNumber, i) => {
        setCurrentPage(pageNumber);
        setBtnPage(i);

    }

    console.log("userDetailuserDetail", userDetail);

    const renderPagination = () => {
        const startPage = Math.floor((btnPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            const pageNumber = (i - 1) * pageSize + 1;  // 각 페이지 그룹의 첫 페이지 계산 (1, 11, 21, 31...)
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === btnPage ? 'active' : ''}`}
                    onClick={() => handerbtn(pageNumber, i)}  // 페이지 버튼을 클릭 시 페이지 그룹의 첫 번째 페이지로 이동
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setBtnPage(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setBtnPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className="adminpage-container">
            <div className="small-title">
                <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                <div className="adminhd-line"></div>
                <h2 className="menu-name">관리자 메뉴</h2>
            </div>
            <div className="menu-list">
                <div className="menu-title">
                    {adminMenu.map((item, index) => (
                        <React.Fragment key={item.id}>
                            <div key={item.id} className="menu-item">
                                <Link to={item.path} style={{ display: 'block', width: '100%', height: '100%' }}>
                                    {item.name}
                                </Link>
                            </div>
                            <div className="admin-under"></div>
                        </React.Fragment>
                    ))}
                </div>
                <div className="admin-line"></div>
                <div className="year-name">
                    <div className="submin-container">
                        <h3>데이터 관리 계획(User별) 상세</h3>
                        <div className="history-select">
                            <div>총 {listCount}건</div>
                        </div>
                        <div className="auth-container">
                            <textarea
                                placeholder="권한 정보 리스트"
                                value={changedProjNos.map((projNo) => `'${projNo}'`).join(", ")}
                                readOnly
                                style={{ width: '100%', height: '52px' }}
                            />
                            <button className="auth-changebtn" onClick={authChangeHandler}>권한 변경</button>
                        </div>
                    </div>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="dmplist-title">
                            <th>과제번호</th>
                            <th>과제이름</th>
                            <th>연차</th>
                            <th>연구책임자 사번</th>
                            <th>연구책임자명</th>
                            <th>권한정보</th>
                        </tr>
                        </thead>
                        <tbody>
                        {userDetail.map((row, index) => (
                            <tr key={index} className="dmplist-Data">
                                <td>{row.ProjNo}</td>
                                <td>{row.ProjNm}</td>
                                <td>{row.ProjAnnl}년차</td>
                                <td>{row.RspnEmpNo}</td>
                                <td>{row.RspnEmpNm}</td>
                                <td>
                                    <label>
                                        <input
                                            className="auth-radio"
                                            type="radio"
                                            name={`authStatus-${index}`}
                                            value="Y"
                                            checked={row.AuthStatus === "Y"}
                                            onChange={() => handleAuthStatusChange(index, "Y")}
                                        />
                                        Y
                                    </label>
                                    <label style={{ marginLeft: '10px' }}>
                                        <input
                                            className="auth-radio"
                                            type="radio"
                                            name={`authStatus-${index}`}
                                            value="N"
                                            checked={row.AuthStatus === "N"}
                                            onChange={() => handleAuthStatusChange(index, "N")}
                                        />
                                        N
                                    </label>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {renderPagination()}
                </div>
            </div>
        </div>
    );
}

export default UserManagementDetail;
