import React, { useState, useEffect } from "react";
import {Link, useNavigate} from 'react-router-dom';
import './header.css';
import useLoginAuthStore from "../stores/loginAuth";
import api from '../api';

function Header() {
    const userid = useLoginAuthStore((state) => state.userId);

    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [isSessionActive, setIsSessionActive] = useState(false); // JSESSIONID 쿠키 체크 상태

    const clearUserId = useLoginAuthStore((state) => state.clearUserId);

    const navigate = useNavigate();


    // 쿠키에서 JSESSIONID가 있는지 확인하는 함수
    const checkCookie = () => {
        const cookies = document.cookie.split('; ');
        const sessionCookie = cookies.find(cookie => cookie.startsWith('JSESSIONID='));
        return sessionCookie ? true : false;
    };

    // 쿠키 변경 여부를 주기적으로 체크하여 세션 상태를 업데이트
    useEffect(() => {
        const interval = setInterval(() => {
            setIsSessionActive(checkCookie()); // 쿠키 여부에 따라 세션 상태 설정
        }, 1000); // 1초마다 쿠키를 확인 (이 시간을 조정할 수 있음)

        return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 제거
    }, []);

    const logouthandler = () => {
        const confirmLogout = window.confirm("정말 로그아웃 하시겠습니까?");
        if (confirmLogout) {
            api.post(`/api/auth/logout`, {}, {
                withCredentials: true
            }).then((res) => {
                alert("로그아웃이 되었습니다.");
                setIsLoggedOut(true); // 로그아웃 상태 업데이트
                clearUserId();  // 상태 초기화
                setIsSessionActive(false); // 세션도 비활성화
            }).catch((err) => console.log(err));
        }
    };

    // 상태 업데이트를 감지하여 강제로 렌더링을 트리거 ( 로그아웃 이후 다시 로그인 할때 상태를 리셋해준다.)
    useEffect(() => {
        if (userid || isLoggedOut) {
            setIsLoggedOut(false); // 로그아웃 후 로그인 시 상태 리셋
        }
    }, [userid, isLoggedOut]); // 상태 변경 감지

    console.log("isSessionActive", isSessionActive, userid , isLoggedOut, clearUserId );

    const loginHandler = () => {
        navigate(`/login`);
    }
    return (
        <>
            <header>
                <div className="container">
                    <div className="logo">
                        <Link to="/">
                            <img src={`${process.env.PUBLIC_URL}/CI_KFRI.png`} alt="KFRI Logo" />
                        </Link>
                    </div>
                    <nav className="navbar">
                        {/*<Link to="/intro/intro" className="dropdown-link">소개</Link>*/}
                        <div className="dropdown">
                            <Link>소개</Link>
                            <div className="dropdown-menu">
                                <Link to="/intro">소개</Link>
                                <Link to="/board/1">공지</Link>
                                <Link to="/board/2">자료실</Link>
                            </div>
                        </div>
                        <Link to="/dmpManagement/dmpList">데이터 관리 계획</Link>
                        <Link to="/dmpRealData/dmpRealDataList">데이터</Link>
                        <Link to="/openData">공개데이터</Link>
                        <Link to="/Curation/list">데이터큐레이션</Link>
                    </nav>
                    <div className="login">
                        {
                            (userid && !isLoggedOut) ? (
                                <div className="login-success" style={{cursor: "pointer"}}>
                                    {
                                        userid === 'admin' ? (
                                            <>
                                                <Link to="/adminPage">
                                                    <img className="header-icon01" src={`${process.env.PUBLIC_URL}/admin-icon.png`} alt="admin" />
                                                </Link> |
                                                <Link to="/myPage">
                                                    <img className="header-icon02" src={`${process.env.PUBLIC_URL}/user-icon.png`} alt="user" />
                                                </Link>
                                            </>
                                        ) :(
                                            <Link to="/myPage">
                                                <img className="header-icon02" src={`${process.env.PUBLIC_URL}/user-icon.png`} alt="user" />
                                            </Link>
                                        )

                                    }
                                    <button style={{cursor: "pointer"}} onClick={logouthandler}>로그아웃</button>
                                </div>
                            ) : (
                                <button style={{cursor: "pointer"}} onClick={loginHandler}>
                                    로그인
                                </button>
                            )
                        }
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
