import React, {useEffect, useState} from "react";
import './myPage.css';
import api from "../api";
import ApplicationPage from "../application/applicationPage";
import {Link, useNavigate} from "react-router-dom";

function OpenDataMyPage() {

    const navigate = useNavigate();
    const [myPageData, setMyPageData] = useState([]);

    const [myPageValue, setMyPageValue] = useState(0); // 총 DMP 값
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호
    const [testcurrenr, setTestcurrenr] = useState(1);

    const [testzz, setTestzz] = useState([]);

    const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수

    const pageSize = 10; // 한 페이지에 표시할 DMP 수
    const pageGroupSize = 10; // 페이지네이션 그룹 크기



    //신청서 보여주기
    const [showPagees, setshowPagees] = useState(false);


    const adminMenu = [
        {
            'id' : 1,
            'name': "마이페이지",
            'path': "/myPage"
        },
        {
            'id' : 2,
            'name': "캘린더(데이터 현황)",
            'path': "/myPage/calendar"
        },

    ]



    useEffect(() => {
        try {
            api.get(`/api/my/applist?ps=${currentPage}&pl=${pageSize}`,{
                withCredentials: true
            }).then((res) => {
                    setMyPageData(res.data.items);

                setTestzz(res.data);

                    setMyPageValue(res.data.pages.Total); // 총 DMP 개수 설정
                    setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
                });
        }catch (err) {
            console.log(err)
        }
    }, [currentPage]);

    console.log("해당 데이터 확인", myPageData);


    const handertest = (pageNumber, i) => {
        setCurrentPage(pageNumber);
        setTestcurrenr(i);

    }


    // 페이지네이션을 렌더링하는 함수
    const renderPagination = () => {
        const startPage = Math.floor((testcurrenr - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            const pageNumber = (i - 1) * pageSize + 1;  // 각 페이지 그룹의 첫 페이지 계산 (1, 11, 21, 31...)
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === testcurrenr ? 'active' : ''}`}
                    onClick={() => handertest(pageNumber, i)}  // 페이지 버튼을 클릭 시 페이지 그룹의 첫 번째 페이지로 이동
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setTestcurrenr(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setTestcurrenr(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    const myPageHandler = () => {
        setshowPagees(true);
    }

    const myPagecancelHandler = () => {
        setshowPagees(false);
    }

    const myPageDataHandler = (DtaSbmSeqNo) => {
        navigate(`/dmpRealData/dmpRealDataView/${DtaSbmSeqNo}`);
    }


    console.log(testzz);

    return (
        <>
            <div className="small-title">
                <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                <div className="adminhd-line"></div>
                <h2 className="menu-name">마이페이지 메뉴</h2>
            </div>
            <div className="mycontainer">
                <div className="menu-title">
                    {adminMenu.map((item,index) => (
                        <React.Fragment key={item.id}>
                            <div className="menu-item">
                                <Link to={item.path} style={{ display: 'block', width: '100%', height: '100%' }}>
                                    {item.name}
                                </Link>
                            </div>
                            <div className="admin-under"></div>
                        </React.Fragment>
                    ))}
                </div>
                <div className="admin-line"></div>
                <div className="main-content">
                    <h2 className="main-content--title">데이터 신청 현황</h2>
                    <div className="mypage-side_line-title"></div>
                    <h3>공개데이터</h3>

                    <div>
                        <div>
                            <div>총 {myPageValue}건</div>
                        </div>
                        <div className="dmpsend-start_line"></div>
                        <table className="education-table">
                            <thead>
                            <tr>
                                <th>DMP정보</th>
                                <th>명칭</th>
                                <th>신청자</th>
                                <th>신청날짜</th>
                                <th>만료날짜</th>
                                <th>상태</th>
                                <th>신청서보기</th>
                                <th>데이터보기</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                myPageData.map((item) => (
                                    (
                                        <tr key={item.JointNo}>
                                            <td>[{item.ProjNo}]<br/>{item.ProjNm}</td>
                                            <td>[{item.DtaSetNm}]<br/>{item.DtaNm}</td>
                                            {showPagees && <ApplicationPage key={item.AppNo} setShowPage={setshowPagees} showPagess={showPagees} AppValue={item.AppNo} myPagecancelHandler={myPagecancelHandler} />}
                                            <td style={{ width: '50px' }}>{item.RspnEmpNm}</td>
                                            <td>{item.StartDt}</td>
                                            <td>{item.EndDt}</td>
                                            <td style={{ width: '45px' }}>
                                                {item.Status === '1' ? '신청'
                                                    : item.Status === '2' ? '승인'
                                                        : item.Status === '3' ? '반려'
                                                            : '-'}
                                            </td>
                                            {/* 신청서 보기 버튼은 항상 활성화 */}
                                            <td style={{ width: '57px' }}>
                                                <>
                                                    <button style={{ cursor: "pointer" }} onClick={myPageHandler}>신청서 보기</button>
                                                </>
                                            </td>
                                            <td style={{ width: '57px' }}>
                                                {/* 데이터 보기 버튼은 승인일 때만 활성화 */}
                                                <button style={{ cursor: "pointer" }} disabled={item.Status !== '2'} onClick={() => myPageDataHandler(item.DtaSbmSeqNo)}>데이터 보기</button>
                                            </td>
                                        </tr>
                                    )
                                ))
                            }

                            </tbody>
                        </table>
                        {renderPagination()}
                    </div>
                </div>
            </div>

        </>
    );
}

export default OpenDataMyPage;
