import React, {useEffect, useState} from "react";
import './myPage.css';
import api from "../api";
import ApplicationPage from "../application/applicationPage";
import {Link, useNavigate} from "react-router-dom";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import {Tooltip} from "react-tooltip";

function MypageCalendar() {

    const [myPageData, setMyPageData] = useState([]);
    const [events, setEvents] = useState([]);

    const adminMenu = [
        {
            'id' : 1,
            'name': "마이페이지",
            'path': "/myPage"
        },
        {
            'id' : 2,
            'name': "캘린더(데이터 현황)",
            'path': "/myPage/calendar"
        },

    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await api.get(`/api/my/mycalendar`, { withCredentials: true });
                setMyPageData(res.data);

                // myPageData를 캘린더의 events 형식으로 변환
                const calendarEvents = res.data.map((item, index) => ([
                    {
                        id: `${item.AppNo}-start-${index}`,
                        title: `시작 : [${item.DtaSetNm}] - ${item.DtaDs}`,
                        start: new Date(item.StartDt),
                        end: new Date(item.StartDt),
                        type: 'start',
                        details: `내용: ${item.DtaDs}<br />작성 날짜: ${item.StartDt}`,
                    },
                    {
                        id: `${item.AppNo}-end-${index}`,
                        title: `종료 : [${item.DtaSetNm}] - ${item.DtaDs}`,
                        start: new Date(item.EndDt),
                        end: new Date(item.EndDt),
                        type: 'end',
                        details: `내용: ${item.DtaDs}<br />작성 날짜: ${item.EndDt}`,
                    }
                ])).flat();

                setEvents(calendarEvents);
            } catch (err) {
                console.error("API Error:", err);
            }
        };

        fetchData();
    }, []);

    moment.locale('ko');

    const localizer = momentLocalizer(moment);

    console.log("myPageData", myPageData);

    return (
        <>
            <div className="small-title">
                <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                <div className="adminhd-line"></div>
                <h2 className="menu-name">마이페이지 메뉴</h2>
            </div>
            <div className="mycontainer">
                <div className="menu-title">
                    {adminMenu.map((item,index) => (
                        <React.Fragment key={item.id}>
                            <div className="menu-item">
                                <Link to={item.path} style={{ display: 'block', width: '100%', height: '100%' }}>
                                    {item.name}
                                </Link>
                            </div>
                            <div className="admin-under"></div>
                        </React.Fragment>
                    ))}
                </div>
                <div className="admin-line"></div>
                <div className="main-content">
                    <h2 className="main-content--title">캘린더(데이터 현황)</h2>
                    <div className="mypage-side_line-title"></div>
                    <div className="mypage-calendar">
                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            // onSelectEvent={handleEventClick} // 이벤트 선택 시 핸들러 실행
                            style={{ height: 770 }}
                            eventPropGetter={(event) => {
                                const backgroundColor = event.type === 'start' ? 'skyblue' : 'orangered';
                                return {
                                    style: {
                                        backgroundColor,
                                        color: 'white',
                                    },
                                    'data-tooltip-id': `tooltip-${event.id}`,
                                    'data-tooltip-html': event.details, // HTML 컨텐츠로 설정
                                };
                            }}
                            components={{
                                event: (props) => (
                                    <span
                                        data-tooltip-id={`tooltip-${props.event.id}`}
                                        data-tooltip-html={props.event.details}
                                    >
                                        {props.title}
                                    </span>
                                ),
                            }}
                        />
                        {events.map((event) => (
                            <Tooltip
                                key={event.id}
                                id={`tooltip-${event.id}`}
                                className="custom-tooltip"
                                place="top-end"
                                type="dark"
                                effect="float"
                                html={true} // HTML을 허용하도록 설정
                            />
                        ))}

                    </div>
                </div>
            </div>

        </>
    );
}

export default MypageCalendar;
