import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import '../submitStatus/dataSubmitStatus.css';
import api from "../../api";
function ApplicationManagementPage() {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); //현재페이지
    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수 (1-10, 11-20 등)

    const [listCount, setListCount] = useState(0); // 총 데이터 개수
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const [btnPage, setBtnPage] = useState(1);

    const navigate = useNavigate();

    const dataSendPage = async (page = 1) => {
        let endPoit = `/api/m/getapp?ps=${page}&pl=${pageSize}`
        try {
            const res = await api.get(endPoit, {
                withCredentials: true,
            });
            setTableData(res.data.item);
            setListCount(res.data.pages.Total); // 총 데이터 개수 설정
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산

        }catch (error) {
            console.err('api 에러를 확인해주세요.', error);
        }
    }


    useEffect(() => {
        dataSendPage(currentPage)
    }, [currentPage]);


    const handerbtn = (pageNumber, i) => {
        setCurrentPage(pageNumber);
        setBtnPage(i);

    }

    const renderPagination = () => {
        const startPage = Math.floor((btnPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            const pageNumber = (i - 1) * pageSize + 1;  // 각 페이지 그룹의 첫 페이지 계산 (1, 11, 21, 31...)
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === btnPage ? 'active' : ''}`}
                    onClick={() => handerbtn(pageNumber, i)}  // 페이지 버튼을 클릭 시 페이지 그룹의 첫 번째 페이지로 이동
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setBtnPage(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setBtnPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    const adminMenu = [
        {
            'id' : 1,
            'name': "캘린더(공개시기 관리)",
            'path': "/adminPage"
        },
        {
            'id' : 2,
            'name': "데이터 제출 현황",
            'path': "/adminPage/submintStatus"
        },
        {
            'id' : 3,
            'name': "데이터 제출 목록 일괄",
            'path': "/adminPage/dataSubmitList"
        },
        {
            'id' : 4,
            'name': "데이터 제출 상태 변경",
            'path': "/adminPage/dataSubmitStatusChange"
        },
        {
            'id' : 5,
            'name': "DMP 작성 현황 및 제출 상태 변경",
            'path': "/adminPage/dmpSubmitStatus"
        },
        {
            'id' : 6,
            'name': "데이터 관리 계획(User별)",
            'path': "/adminPage/userManagement"
        },
        {
            'id' : 7,
            'name': "데이터관리 계획 접근권한(과제별)",
            'path': "/adminPage/dmpAccessCheck"
        },
        {
            'id' : 8,
            'name': "생산계획 누락현황 확인",
            'path': "/adminPage/productionPlanMng"
        },
        {
            'id' : 9,
            'name': "신청서 관리 페이지",
            'path': "/adminPage/applicationManagement"
        },
    ]

    const handleRowClick = (AppNo) => {
        navigate(`/adminPage/applicationManagement/detail/${AppNo}`);
    }

    console.log("tableData",tableData);

    return(
        <>
            <div className="adminpage-container">
                <div className="small-title">
                    <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                    <div className="adminhd-line"></div>
                    <h2 className="menu-name">관리자 메뉴</h2>
                </div>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <div className="menu-item">
                                    <Link to={item.path} style={{ display: 'block', width: '100%', height: '100%' }}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className="admin-under"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="admin-line"></div>
                    <div className="year-name">
                        <div className="submin-container">
                            <h3>
                                신청서 관리 페이지
                            </h3>
                            <div className="history-select">
                                <div>총 {listCount}건</div>
                            </div>

                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>신청서 번호</th>
                                <th>이용책임자 사번</th>
                                <th>이용책임자명</th>
                                <th>주제</th>
                                <th>이용 목적</th>
                                <th>이용 계획</th>
                                <th>작성일자</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData?.map((row) => (
                                <tr key={row.AppNo} className="dmplist-Data">
                                    <td>{row.AppNo}</td>
                                    <td>{row.RespUserNo}</td>
                                    <td>{row.RespUserNm}</td>
                                    <td>{row.Subject}</td>
                                    <td>{row.Purpose}</td>
                                    <td style={{ cursor: 'pointer' }}
                                        onClick={() => handleRowClick(row.AppNo)}
                                    >{row.UsagePlan}
                                    </td>
                                    <td>{row.InsDt}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {
                            renderPagination()
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export  default ApplicationManagementPage;