import React, { useEffect, useRef, useState } from "react";
import Grid from "@toast-ui/react-grid";
import '../toast/toastGrid.css';
import { useNewDmpProductionPlan } from "../stores/newWriteDmp";

function DmpDataProductionPlan({ updateProductionPn, DmpMgmtHisNo, year, id, setAllData, proplanDataTypes }) {
    const gridRef = useRef(null);
    // const { setNewProductionPlandataSets } = useNewDmpProductionPlan();
    const [cellValues, setCellValues] = useState([]);



    // Update the cell values when the component receives new data via props (updateProductionPn)
    useEffect(() => {
        if (updateProductionPn && updateProductionPn?.Prod) {
            // 연도별로 데이터를 필터링하여 해당 연도 데이터만 처리
            const formattedData = updateProductionPn.Prod
                .filter(item => String(item.PrdYear) === String(year)) // 연도 비교를 문자열로 변환하여 처리
                .map(item => ({
                    DmpPrdNo: item.DmpPrdNo,
                    DmpNo: item.DmpNo,
                    PrdYear: item.PrdYear,
                    dataType: item.DtaNm,
                    count: item.ProCnt,
                    proPlan: item.ProPlan,
                    InsDt: item.InsDt,
                    UpdDt: item.UpdDt,
                    delete: '삭제',
                }));

            setCellValues(formattedData);
        } else {
            setCellValues([]); // 또는 초기값을 설정
        }
    }, [updateProductionPn, year]);

    const columns = [
        {
            header: '데이터타입명',
            name: 'dataType',

            editor: DmpMgmtHisNo ? false : {
                type: 'select',
                options: {
                    listItems: Array.isArray(proplanDataTypes)  // proplanDataTypes가 배열인지 확인
                        ? proplanDataTypes.map(type =>
                            typeof type === 'object' && type.DtaNm
                                ? { text: type.DtaNm, value: type.DtaNm }  // 객체일 때 처리
                                : { text: type, value: type }  // 문자열일 때 처리
                        )
                        : Array.isArray(proplanDataTypes?.Summary)
                            ? proplanDataTypes.Summary.map(type => ({
                                text: type.DtaNm || type,  // 객체일 경우 DtaNm 속성을 사용
                                value: type.DtaNm || type  // 객체일 경우 DtaNm 속성을 사용
                            }))
                            : []
                }
            },
            // editor: DmpMgmtHisNo ? false : 'text',
            width: 300,
        },
        {
            header: '개수',
            name: 'count',
            editor: DmpMgmtHisNo ? false : 'text',
            width: 300
        },
        {
            header: '생산계획',
            name: 'proPlan',
            editor: DmpMgmtHisNo ? false : 'text',
            width: 300
        },
        {
            header: 'Delete',
            name: 'delete',
            formatter: ({ row }) => DmpMgmtHisNo
                ? `<span></span>`
                : `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`,
            width: 100,
        }
    ];

    // Adding a new row
    const handleAddRow = () => {
        const newRow = {
            id: Date.now(),
            PrdYear: year,
            dataType: '-',
            count: '-',
            proPlan: '-',
        };
        setCellValues(prevValues => [...prevValues, newRow]);
    };

    // Deleting a row
    const handleDeleteRow = (rowKey) => {
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            console.error("Invalid rowKey:", rowKey);
            return;
        }

        // 해당 rowKey에 맞는 항목을 삭제하고 새로운 배열을 생성
        const updatedCellValues = cellValues.filter(row => row.rowKey !== parsedRowKey);

        // 상태를 업데이트하여 화면을 다시 렌더링하도록 함
        setCellValues(updatedCellValues);

        // 삭제 후에도 cellValues가 빈 배열이면 이를 반영하여 처리
        if (updatedCellValues.length === 0) {
            setAllData(prevData => ({
                ...prevData,
                [id]: []  // 연도별로 비어있는 데이터를 저장
            }));
        }
    };


    useEffect(() => {
        const grid = gridRef.current.getInstance();
        grid.on('click', (ev) => {
            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey);
            }
        });

        if (Array.isArray(cellValues)) {
            setTimeout(() => {
                setAllData(prevData => ({
                    ...prevData,
                    [id]: cellValues // 연도별로 데이터를 저장, 빈 배열도 저장됨
                }));
            }, 8);  // 비동기 처리로 타이밍 조정
        }

    }, [cellValues, setAllData]);

    const handleAfterChange = (ev) => {

        if (ev && ev.changes && ev.changes.length > 0) {
            const updatedData = [...cellValues];

            ev.changes.forEach(({ rowKey, columnName, value }) => {
                if (updatedData[rowKey]) {
                    updatedData[rowKey] = { ...updatedData[rowKey], [columnName]: value };
                }
            });

            setCellValues(updatedData);
        }
    };

    return (
        <div className="toast-container">
            { (!DmpMgmtHisNo) && (
                <div className="row-btn">
                    <button className="public-data-addrow" type="button" onClick={handleAddRow}>
                        행추가
                    </button>
                </div>
            )}

            <Grid
                data={cellValues}
                ref={gridRef}
                rowHeight={'auto'}
                bodyHeight={300}
                columns={columns}
                rowHeaders={['rowNum']}
                editable={true}  // 셀 편집 가능
                copyOptions={{ useFormattedValue: true }}  // 복사-붙여넣기 가능하게 설정
                onAfterChange={handleAfterChange}
            />
        </div>
    );
}

export default DmpDataProductionPlan;
