import React, { useCallback, useEffect, useState } from "react";
import './dmplist.css';
import { useNavigate } from "react-router-dom";
import api from '../../api';
import { debounce } from "lodash";

function Dmplist() {
    const [dmpList, setDmpList] = useState([]); // 현재 표시되는 DMP 리스트
    const [searchTerm, setSearchTerm] = useState(""); // 검색어 상태
    const [listCount, setListCount] = useState(0); // 총 데이터 개수
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지

    const [btnPage, setBtnPage] = useState(1);

    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const [selectedAnnl, setSelectedAnnl] = useState(""); // 선택된 연차 상태 (select 박스)
    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수 (1-10, 11-20 등)

    const navigate = useNavigate();

    // DMP 리스트 API 호출 함수
    const fetchData = async (searchTerm = "", annl = "", page = 1) => {
        // 연차(annl) 값이 있을 때만 쿼리스트링에 추가
        let endpoint = `/api/dmp/dmplist?kw=${searchTerm}&ps=${page}&pl=${pageSize}&pg=${''}`;
        if (annl) {
            endpoint += `&annl=${annl}`;
        }

        try {
            const res = await api.get(endpoint, {
                withCredentials: true,
            });
            setDmpList(res.data.items || res.data.item);
            setListCount(res.data.pages.Total); // 총 데이터 개수 설정
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // 페이지가 변경되거나 DMP 리스트 조회 방식이 변경될 때 데이터 가져오기
    useEffect(() => {
        fetchData(searchTerm, selectedAnnl, currentPage);
    }, [currentPage, selectedAnnl]);

    const dmpRowClick = async (id) => {
        navigate(`/dmpManagement/dmpModify/${id}`, {
        });
    };

    // 디바운스된 함수 생성 (300ms)
    const debouncedFetchData = useCallback(
        debounce((value, page) => fetchData(value, selectedAnnl, page), 300),
        [selectedAnnl]
    );

    // 검색어 입력 처리
    const handlerInputChange = (event) => {
        const value = event.target.value;

        // 입력된 값의 길이가 14자를 넘으면 alert를 띄우고 값을 초기화
        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm(""); // 값을 초기화
            event.target.value = ""; // input 필드도 초기화
            return;
        }

        setSearchTerm(value);
        setCurrentPage(1);
        debouncedFetchData(value, 1); // 디바운스된 API 호출
    };

    // 연차(annl) 값 변경 처리
    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedAnnl(value); // 선택된 연차를 상태로 저장
        setCurrentPage(1); // 페이지 초기화
    };

    // 마지막 페이지에서의 데이터 슬라이싱 처리


    const handerbtn = (pageNumber, i) => {
        setCurrentPage(pageNumber);
        setBtnPage(i);

    }

    // 페이지네이션 렌더링 함수
    const renderPagination = () => {
        const startPage = Math.floor((btnPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            const pageNumber = (i - 1) * pageSize + 1;  // 각 페이지 그룹의 첫 페이지 계산 (1, 11, 21, 31...)
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === btnPage ? 'active' : ''}`}
                    onClick={() => handerbtn(pageNumber, i)}  // 페이지 버튼을 클릭 시 페이지 그룹의 첫 번째 페이지로 이동
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setBtnPage(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setBtnPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    const handleDmpHisBtn = (DmpNo) => {
        navigate(`/dmpManagement/dmpHisPageList/${DmpNo}`);
    }

    console.log("dmpList", dmpList);

    return (
        <>
            <div className="dmplist-container">
                <div className="dmplist-banner-img">
                    <img
                        src={`${process.env.PUBLIC_URL}/sub-introimg02.jpg`}
                        className="dmplist-img02"
                        alt="search Logo"
                    />
                    <div className="banner-text">
                        <span className="intro-subName">데이터 관리 계획</span>
                    </div>
                    <div className="dmplist-description">
                        <div className="dmplist-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > DMP 목록</span>
                        </div>
                    </div>
                </div>
                <div className="dmplist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input
                        type="text"
                        placeholder="검색어를 입력해주세요."
                        value={searchTerm}
                        onChange={handlerInputChange}
                    />
                </div>

                {/* 연차 필터링을 위한 select 박스 */}
                {/*<div className="dmplist-selectbox">*/}
                {/*    <label className="annl-number" htmlFor="annl-select">연차 선택:</label>*/}
                {/*    <select id="annl-select" value={selectedAnnl} onChange={handleSelectChange}>*/}
                {/*        <option value="">전체</option>*/}
                {/*        <option value="2022">2022</option>*/}
                {/*        <option value="2023">2023</option>*/}
                {/*        <option value="2024">2024</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
                <div className="dmplist-selectbox">
                    <label className="annl-number" htmlFor="annl-select">연차 선택:</label>
                    <select id="annl-select" value={selectedAnnl} onChange={handleSelectChange}>
                        <option value="">전체</option>
                        {Array.from({ length: new Date().getFullYear() - 2021 }, (_, index) => {
                            const year = 2022 + index;
                            return (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            );
                        })}
                    </select>
                </div>

                <div>
                    <div className="btn-container">
                        <div className="history-select">
                            <div>총 {listCount}건</div>
                        </div>
                        <div className="btn-check">
                            <button className="send02-dmp" >
                                작성 안내                            </button>
                            <button className="create02-dmp" onClick={() => navigate("/dmpobj/dmpObjCreate")}>
                                과제 등록
                            </button>
                            <button className="create02-dmp" onClick={() => navigate("/dmpManagement/dmpCreate")}>
                                DMP 등록
                            </button>
                            <button className="send02-dmp" onClick={() => navigate("/dmpManagement/dmpSend")}>
                                DMP 제출
                            </button>
                        </div>
                    </div>
                    <div className="dmplist-start_line"></div>

                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                        <tr className="dmplist-title">
                            <th className="obn01">과제번호</th>
                            <th className="obn02">연차</th>
                            <th className="obn03">과제명</th>
                            <th className="obn03">이력 내역</th>
                            <th className="obn04">연구 책임자</th>
                            <th className="obn05">DMP 상태</th>
                            <th className="obn06">마지막수정일</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dmpList.map((row, index) => (
                            <tr key={index} className="dmplist-Data">
                                <td>{row.ProjNo}</td>
                                <td>{row.ProjAnnl}년차</td>
                                <td
                                    style={{ cursor: "pointer" }}
                                    onClick={() => dmpRowClick(row.DmpNo)}
                                >
                                    {row.ProjNm}

                                    {row.SubmitStatus === "0" ? (
                                        <span className="tmp01-status">임시저장중...</span>
                                    ) : (
                                        null
                                    )}
                                </td>
                                <td>
                                    <button
                                        className="data-hisbtn"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.stopPropagation();  // 부모의 onClick 이벤트가 실행되지 않도록 중지
                                            handleDmpHisBtn(row.DmpNo);  // 버튼 클릭 시 별도 동작 수행
                                        }}
                                    >
                                        이력보기
                                    </button>
                                </td>
                                <td>{row.RsPnEmpNm}</td>
                                <td>
                                    {row.SubmitStatus === "1" ? "저장" :
                                        row.SubmitStatus === "2" ? "제출" :
                                            row.SubmitStatus === "3" ? "검토완료" :
                                                row.SubmitStatus === "4" ? "검토완료(의견있음)" :
                                                    ""}
                                </td>
                                <td>{row.HisInsDT || row.UpdDt}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* 페이지네이션 추가 */}
                    {renderPagination()}
                </div>
            </div>
        </>
    );
}

export default Dmplist;
