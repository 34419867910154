import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import '../submitStatus/dataSubmitStatus.css';
import api from "../../api";
function DmpAccessCheck() {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); //현재페이지

    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수 (1-10, 11-20 등)


    const [listCount, setListCount] = useState(0); // 총 데이터 개수

    const [cntPrj, setCntPrj] = useState(0);
    const [cntSave, setCntSave] = useState(0);
    const [cntSubmit, setCntSubmit] = useState(0);

    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수

    const [btnPage, setBtnPage] = useState(1);

    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [years, setYears] = useState([]);

    // 2022년부터 현재 연도까지의 배열을 생성
    useEffect(() => {
        const startYear = 2022;
        const yearList = [];
        for (let year = startYear; year <= currentYear; year++) {
            yearList.push(year);
        }
        setYears(yearList);
    }, [currentYear]);

    useEffect(() => {
        let prj = 0, save = 0, submit = 0;

        // setListCount.forEach(v => {
        //     if (v.TOTCNT > 0) prj++;
        //     if (v.CNT1 > 0) save++;
        //     if (v.CNT2 > 0) submit++;
        // });

        setCntPrj(prj);
        setCntSave(save);
        setCntSubmit(submit);
    }, [listCount]);

    const dataSendPage = async (page = 1) => {
        let endPoit = `/api/m/dmpauth/${Number(selectedYear)}?ps=${page}&pl=${pageSize}`
        try {
            const res = await api.get(endPoit, {
                withCredentials: true,
            });
            setTableData(res.data.item);
            setListCount(res.data.pages.Total); // 총 데이터 개수 설정
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산

        }catch (error) {
            console.err('api 에러를 확인해주세요.', error);
        }
    }

    const totalTasks = listCount.length;
    const saveRate = totalTasks > 0 ? ((cntSave / totalTasks) * 100).toFixed(2) : 0;
    const submitRate = totalTasks > 0 ? ((cntSubmit / totalTasks) * 100).toFixed(2) : 0;



    useEffect(() => {
        dataSendPage(currentPage)
    }, [currentPage,selectedYear]);


    const handerbtn = (pageNumber, i) => {
        setCurrentPage(pageNumber);
        setBtnPage(i);

    }

    const renderPagination = () => {
        const startPage = Math.floor((btnPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            const pageNumber = (i - 1) * pageSize + 1;  // 각 페이지 그룹의 첫 페이지 계산 (1, 11, 21, 31...)
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === btnPage ? 'active' : ''}`}
                    onClick={() => handerbtn(pageNumber, i)}  // 페이지 버튼을 클릭 시 페이지 그룹의 첫 번째 페이지로 이동
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setBtnPage(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setBtnPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    const adminMenu = [
        {
            'id' : 1,
            'name': "캘린더(공개시기 관리)",
            'path': "/adminPage"
        },
        {
            'id' : 2,
            'name': "데이터 제출 현황",
            'path': "/adminPage/submintStatus"
        },
        {
            'id' : 3,
            'name': "데이터 제출 목록 일괄",
            'path': "/adminPage/dataSubmitList"
        },
        {
            'id' : 4,
            'name': "데이터 제출 상태 변경",
            'path': "/adminPage/dataSubmitStatusChange"
        },
        {
            'id' : 5,
            'name': "DMP 작성 현황 및 제출 상태 변경",
            'path': "/adminPage/dmpSubmitStatus"
        },
        // {
        //     'id' : 6,
        //     'name': "DMP 계획 및 제출 현황",
        //     'path': "/adminPage/dmpPlanStatus"
        // },
        {
            'id' : 6,
            'name': "데이터 관리 계획(User별)",
            'path': "/adminPage/userManagement"
        },
        {
            'id' : 7,
            'name': "데이터관리 계획 접근권한(과제별)",
            'path': "/adminPage/dmpAccessCheck"
        },
        {
            'id' : 8,
            'name': "생산계획 누락현황 확인",
            'path': "/adminPage/productionPlanMng"
        },
        {
            'id' : 9,
            'name': "신청서 관리 페이지",
            'path': "/adminPage/applicationManagement"
        },
    ]
    console.log("일괄 데이터 확인 ",tableData)

    return(
        <>
            <div className="adminpage-container">
                <div className="small-title">
                    <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                    <div className="adminhd-line"></div>
                    <h2 className="menu-name">관리자 메뉴</h2>
                </div>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <div className="menu-item">
                                    <Link to={item.path} style={{ display: 'block', width: '100%', height: '100%' }}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className="admin-under"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="admin-line"></div>
                    <div className="year-name">
                        <div className="submin-container">
                            <h3>
                                DMP 접근권한 확인
                            </h3>
                            <h4>※ 조회하고자 하는 연도를 아래에서 선택해주세요.</h4>
                            <div>
                                <div className="accessCheck-year">과제수행년도
                                    <select
                                        value={selectedYear}
                                        className="submin-year"
                                        onChange={(e) => setSelectedYear(e.target.value)}
                                    >
                                        <option value=''>전체</option>
                                        {years.map((year, index) => (
                                            <option key={index} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="history-select">
                                <div>총 {listCount}건</div>
                            </div>

                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>과제번호</th>
                                <th>과제수행년도</th>
                                <th>과제명</th>
                                <th>연책</th>
                                <th>데이터담당자명</th>
                                <th>연차시작일</th>
                                <th>연차종료일</th>
                                <th>접근권한자</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData?.map((row, index) => (
                                <tr key={index} className="dmplist-Data">
                                    <td>{row.ProjNo}</td>
                                    <td>{row.AnnlYy}</td>
                                    <td>{row.ProjNm}</td>
                                    <td>{row.RspnEmpNm}({row.RspnEmpNo})</td>
                                    <td>{row.DataEmpNm}({row.DataEmpNo})</td>
                                    <td>{row.ProjAnnlDateSt}</td>
                                    <td>{row.ProjAnnlDateEd}</td>
                                    <td>{row.AuthUser}</td>

                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {
                            renderPagination()
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export  default DmpAccessCheck;