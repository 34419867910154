import React, {useEffect, useRef, useState} from "react";
import Grid from "@toast-ui/react-grid";
import '../toast/toastGrid.css';
import {useNewDmpManagementPlan} from "../stores/newWriteDmp";

function DataManagementGrid({updateProductionPn, DmpProductionHisNo ,proplanDataTypes}) {
    const gridRef = useRef(null);

    const { setNewManagementPlandataSets } = useNewDmpManagementPlan();

    const [mnCellValue, setMnCellValue] = useState([]);


    //연구데이터 관리 계획
    // const {setManagementDataSets} = useManagementDataStore()

    useEffect(() => {
        if(updateProductionPn && updateProductionPn.Mnmt) {
            const addList = updateProductionPn.Mnmt.map(item => ({
                    DmpMgmtNo:item.DmpMgmtNo,
                    DmpNo:item.DmpNo,
                    dataType:item.DtaNm,
                    method:item.MngMth,
                    DmpPrdNo:item.DmpPrdNo,
                    individualManagement:item.MngResn,
                    dataLocation:item.MngPos,
                    storagePeriod:item.MngPd,
                    periodExpires:item.MngOpnMth,
                    DelFlg:item.DelFlg,
                    InsDt: item.InsDt,
                    UpdDt: item.UpdDt,
                    delete:'삭제',
                }))
            setMnCellValue(addList);
        }
        else {
            setMnCellValue([]); // 또는 초기값을 설정
        }
    }, [updateProductionPn]);

    const columns = [
        {
            header: '데이터타입명',
            name: 'dataType',
            editor: DmpProductionHisNo ? false : {
                type: 'select',
                options: {
                    listItems: Array.isArray(proplanDataTypes)
                        ? proplanDataTypes.map(type =>
                            typeof type === 'object' && type.DtaNm
                                ? { text: type.DtaNm, value: type.DtaNm }
                                : { text: type, value: type }
                        )
                        : Array.isArray(proplanDataTypes?.Summary)
                            ? proplanDataTypes.Summary.map(type => ({
                                text: type.DtaNm || type,
                                value: type.DtaNm || type
                            }))
                            : []
                }
            },
            width: 200,
        },
        {
            header: '관리방법',
            name: 'method',
            editor: DmpProductionHisNo ? false : {
                type: 'select',
                options: {
                    listItems: [
                        { text: 'DMP 플랫폼', value: 'DMP 플랫폼' },
                        { text: '개별관리', value: '개별관리' }
                    ]
                }
            },
            width: 140
        },
        {
            header: '개별관리 사유',
            name: 'individualManagement',
            // editor를 함수로 전달하여 동적으로 편집 가능 여부를 설정
            editor: DmpProductionHisNo ? false : 'text',
            width: 140
        },
        {
            header: '데이터보관 방법',
            name: 'dataLocation',
            editor: DmpProductionHisNo ? false : 'text',
            width: 140
        },
        {
            header: '데이터공개방법',
            name: 'storagePeriod',
            editor: DmpProductionHisNo ? false : 'text',
            width: 140
        },
        {
            header: '데이터공개시점',
            name: 'periodExpires',
            editor: DmpProductionHisNo ? false : 'text',
            width: 210
        },
        {
            header: '행 삭제',
            name: 'delete',
            formatter: ({ row }) => DmpProductionHisNo
                ? `<span></span>`
                : `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`,
            width: 100,
        }
    ];

    const handleAddRow = () => {
        const newRow = {
            id: Date.now(), // Using current timestamp as a unique ID
            dataType:'-',
            method:'-',
            individualManagement:'-',
            dataLocation:'-',
            storagePeriod:'-',
            periodExpires:'-',
        };
        setMnCellValue(prevList => [...prevList, newRow]);
    };

    // const handleDeleteRow = (rowKey) => {
    //     const parsedRowKey = parseInt(rowKey, 10);
    //     if (isNaN(parsedRowKey)) {
    //         console.error("Invalid rowKey:", rowKey);
    //         return;
    //     }
    //     setMnCellValue(prevList => prevList.filter(row => row.rowKey !== parsedRowKey));
    // };

    const handleDeleteRow = (rowKey) => {
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            console.error("Invalid rowKey:", rowKey);
            return;
        }
        setMnCellValue(prevList => {
            const updatedList = prevList.filter(row => row.rowKey !== parsedRowKey);

            // 모든 행이 삭제되었을 때 빈 배열 반환
            if (updatedList.length === 0) {
                return [];
            }
            return updatedList;
        });
    };



    useEffect(() => {
        const grid = gridRef.current.getInstance();
        grid.on('click', (ev) => {
            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey);
            }
        });

        // mnCellValue 배열이 빈 배열이라도 상태를 업데이트하도록 수정
        if (mnCellValue.length === 0) {
            setNewManagementPlandataSets([]);
        } else {
            setTimeout(() => {
                setNewManagementPlandataSets(mnCellValue);
            }, 8);  // 비동기 처리로 타이밍 조정
        }
    }, [mnCellValue, setNewManagementPlandataSets]);


    const handleGridUpdated = (ev) => {
        console.log("행의 속성값 확인", ev);

        if (ev && ev.changes && ev.changes.length > 0) {
            const updatedData = [...mnCellValue];

            ev.changes.forEach(({ rowKey, columnName, value }) => {
                // 변경된 행의 'method' 값이 'DMP 플랫폼'인지 확인
                if (updatedData[rowKey] && updatedData[rowKey].method === 'DMP 플랫폼' && columnName !== 'method') {
                    // 'method'가 'DMP 플랫폼'일 때 다른 컬럼을 수정하지 않음
                    alert(`'method'가 'DMP 플랫폼'이므로 ${columnName} 열의 작성이 불가합니다.`);
                    return;
                }

                // 'method'가 'DMP 플랫폼'이 아닌 경우에만 값을 업데이트
                if (updatedData[rowKey]) {
                    updatedData[rowKey] = { ...updatedData[rowKey], [columnName]: value };
                }
            });

            setMnCellValue(updatedData);
        }
    };



    return (
        <div className="toast-container">
            { (!DmpProductionHisNo) && (
                <div className="row-btn">
                    <button className="public-data-addrow" type="button" onClick={handleAddRow}>
                        행추가
                    </button>
                </div>
            )}

            <Grid
                data={mnCellValue}
                ref={gridRef}
                columns={columns}
                rowHeight={'auto'}
                bodyHeight={300}
                rowHeaders={['rowNum']}
                editable={true}  // 셀 편집 가능
                copyOptions={{ useFormattedValue: true }}  // 복사-붙여넣기 가능하게 설정
                onAfterChange ={handleGridUpdated}
            />
        </div>
    );
}

export default DataManagementGrid;
