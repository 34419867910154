import create from 'zustand';
import { persist } from 'zustand/middleware';  // Zustand persist 사용

const useLoginAuthStore = create(
    persist(
        (set) => ({
            userId: null,
            setUserId: (id) => set({ userId: id }),
            clearUserId: () => set({ userId: null }),
        }),
        {
            name: 'loginAuthStore',
            getStorage: () => localStorage,
        }
    )
);

export default useLoginAuthStore;