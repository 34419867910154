import React, {useState} from "react";
import './dmpObjCreate.css'
import {Link, useNavigate} from "react-router-dom";
import api from '../api';

function DmpObjCreate() {

    const [objNumber ,setObjNumber] = useState("");
    const [objName, setObjName] = useState("");
    const [objLeader, setObjLeader] = useState("");
    const [rspnEmpNo, setRspnEmpNo] = useState("");
    const [dataEmpNo, setDataEmpNo] = useState("");
    const [dataEmpNm, setDataEmpNm] = useState("");

    //년차 선택
    const [selectedAnnl, setSelectedAnnl] = useState("");

    //과제 시작일자
    const currentYear = new Date().getFullYear();
    const [projAnnlDateSt, setProjAnnlDateSt] = useState(`${currentYear}-01-01`);
    const [projAnnlDateEd, setProjAnnlDateEd] = useState(`${currentYear}-12-31`);

    const [projNameDateSt, setProjNameDateSt] = useState('');
    const [projNameDateEd, setProjNameDateEd] = useState('');



    const navigate = useNavigate();

    const ProjSaveBtn = () => {
        try{
            api.post(`/api/dmp/proj` ,
                {
                ProjNo: objNumber,
                ProjNm: objName,
                RspnEmpNo : rspnEmpNo,
                RspnEmpNm: objLeader,
                DataEmpNo : dataEmpNo,
                DataEmpNm : dataEmpNm,
                ProjAnnl : selectedAnnl,
                ProjDateSt : projNameDateSt,
                ProjDateEd : projNameDateEd,
                ProjAnnlDateSt : projAnnlDateSt,
                ProjAnnlDateEd : projAnnlDateEd,
            }, {withCredentials: true},).then((res) =>
                console.log(res.data)
            )
            alert("과제번호 등록이 완료 되었습니다.");
            setObjNumber("");
            setObjName("");
            setObjLeader("");
            navigate("/dmpManagement/dmpList");

        }catch (err) {
            console.error(err);
            alert("과제 데이터 api 통신을 확인해보세요.")
        }

    }


    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedAnnl(value); // 선택된 연차를 상태로 저장
    };
    const handleProStart = (e) => {
        setProjNameDateSt(e.target.value);
    };
    const handleProEnd = (e) => {
        setProjNameDateEd(e.target.value);
    };



    const handleStartDateChange = (e) => {
        setProjAnnlDateSt(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setProjAnnlDateEd(e.target.value);
    };
    return(
        <>
            <div className="dmpObjData-banner-container">
                <div className="dmpObjData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg02.jpg`} className="dmpObjData-img02" alt="search Logo" />
                    <div className="RealDatabanner-text">
                        <span className="intro-subName">과제 등록</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획> 과제 등록</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>과제 등록</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="RealData-object-info">
                        <div className="RealData-object-info-title">
                            <span className="RealData-info">과제번호</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box" style={{width: '300px'}} placeholder="과제번호를 입력해주세요."
                                   onChange={(e) => setObjNumber(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>과제명</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box" style={{width: '300px'}} placeholder="과제명을 입력해주세요."
                                   onChange={(e) => setObjName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>연구 책임자 사번</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box" style={{width: '300px'}} placeholder="연구책임자 사번을 입력해주세요."
                                   onChange={(e) => setRspnEmpNo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>데이터 담당자 사번</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box" style={{width: '300px'}} placeholder="데이터담당자 사번을 입력해주세요."
                                   onChange={(e) => setDataEmpNo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>데이터명</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box" style={{width: '300px'}} placeholder="데이터명을 입력해주세요."
                                   onChange={(e) => setDataEmpNm(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>연차 선택</span>
                            <span className="required_color">*</span>
                        </div>
                            <select id="annl-select" value={selectedAnnl} onChange={handleSelectChange}>
                                <option value="">전체</option>
                                <option value="1">1년차</option>
                                <option value="2">2년차</option>
                                <option value="3">3년차</option>
                                <option value="4">4년차</option>
                                <option value="5">5년차</option>
                                <option value="6">6년차</option>
                                <option value="7">7년차</option>
                                <option value="8">8년차</option>
                                <option value="9">9년차</option>
                                <option value="10">10년차</option>
                            </select>
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>과제 시작일</span>
                            <span className="required_color">*</span>
                        </div>
                        <input
                            style={{height: '30px', fontSize: '16px', width : '24%'}}
                            type="date"
                            value={projNameDateSt}
                            onChange={handleProStart}
                        />
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>과제 종료일</span>
                            <span className="required_color">*</span>
                        </div>
                        <input
                            style={{height: '30px', fontSize: '16px', width : '24%'}}
                            type="date"
                            value={projNameDateEd}
                            onChange={handleProEnd}
                        />
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>과제 연차 시작일</span>
                            <span className="required_color">*</span>
                        </div>
                        <input
                            style={{height: '30px', fontSize: '16px', width : '24%'}}
                            type="date"
                            value={projAnnlDateSt}
                            onChange={handleStartDateChange}
                        />
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>과제 연차 종료일</span>
                            <span className="required_color">*</span>
                        </div>
                        <input
                            style={{height: '30px', fontSize: '16px', width : '24%'}}
                            type="date"
                            value={projAnnlDateEd}
                            onChange={handleEndDateChange}
                        />
                    </div>
                    <div className="start_line"></div>
                    <div className="targetData">
                        <div className="targetData-title">
                            <span>연구 책임자</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <input type="text" className="edit--box"  style={{width: '300px'}} placeholder="연구 책임자를 적어주세요."
                                   onChange={(e) => setObjLeader(e.target.value)}
                            />
                        </div>
                    </div>
                </section>

                <div className="RealData-save-btn-container">
                    <button id="dmp_list">
                        <Link to="/dmpManagement/dmpList"> 목록</Link>
                    </button>
                    <button id="save-btn" onClick={() =>ProjSaveBtn()} >저장</button>
                </div>
            </div>
        </>
    )
}


export default DmpObjCreate