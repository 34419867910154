import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import '../submitStatus/dataSubmitStatus.css';
import api from "../../api";

function ApplicationManagementDetail() {
    const { AppNo } = useParams();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const pageGroupSize = 10;
    const [listCount, setListCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [btnPage, setBtnPage] = useState(1);

    const [checkedItems, setCheckedItems] = useState({});
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [submitStatuses, setSubmitStatuses] = useState({});

    const dataSendPage = async (page = 1) => {
        let endPoit = `/api/m/applist?AppNo=${AppNo}&ps=${page}&pl=${pageSize}`;
        try {
            const res = await api.get(endPoit, { withCredentials: true });
            setTableData(res.data.item);
            setListCount(res.data.pages.Total);
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize));

            const initialStatuses = {};
            res.data.item.forEach(row => {
                initialStatuses[row.JointNo] = row.Status; // JointNo를 키로 사용하여 초기 상태 설정
            });
            setSubmitStatuses(initialStatuses);
        } catch (error) {
            console.error('API 에러를 확인해주세요.', error);
        }
    };

    const adminMenu = [
        {
            'id' : 1,
            'name': "캘린더(공개시기 관리)",
            'path': "/adminPage"
        },
        {
            'id' : 2,
            'name': "데이터 제출 현황",
            'path': "/adminPage/submintStatus"
        },
        {
            'id' : 3,
            'name': "데이터 제출 목록 일괄",
            'path': "/adminPage/dataSubmitList"
        },
        {
            'id' : 4,
            'name': "데이터 제출 상태 변경",
            'path': "/adminPage/dataSubmitStatusChange"
        },
        {
            'id' : 5,
            'name': "DMP 작성 현황 및 제출 상태 변경",
            'path': "/adminPage/dmpSubmitStatus"
        },
        // {
        //     'id' : 6,
        //     'name': "DMP 계획 및 제출 현황",
        //     'path': "/adminPage/dmpPlanStatus"
        // },
        {
            'id' : 6,
            'name': "데이터 관리 계획(User별)",
            'path': "/adminPage/userManagement"
        },
        {
            'id' : 7,
            'name': "데이터관리 계획 접근권한(과제별)",
            'path': "/adminPage/dmpAccessCheck"
        },
        {
            'id' : 8,
            'name': "생산계획 누락현황 확인",
            'path': "/adminPage/productionPlanMng"
        },
        {
            'id' : 9,
            'name': "신청서 관리 페이지",
            'path': "/adminPage/applicationManagement"
        },
    ]

    useEffect(() => {
        dataSendPage(currentPage);
    }, [currentPage]);

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setIsAllChecked(isChecked);
        const newCheckedItems = {};
        tableData.forEach(row => {
            newCheckedItems[row.JointNo] = isChecked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event, jointNo) => {
        const isChecked = event.target.checked;
        setCheckedItems(prevState => ({
            ...prevState,
            [jointNo]: isChecked,
        }));
    };

    const handleStatusChange = (e, jointNo) => {
        const newStatus = e.target.value;
        setSubmitStatuses(prevStatuses => ({
            ...prevStatuses,
            [jointNo]: newStatus,  // JointNo를 사용하여 상태 업데이트
        }));
    };

    const handleReturnChange = async () => {

        try {
            const status = "3";  // 선택된 첫 항목의 상태를 기준으로 설정
            const response = await api.patch(`/api/m/allappstatus/${AppNo}/${status}`, null, { withCredentials: true });
            alert("상태가 성공적으로 일괄 업데이트되었습니다.");

            await dataSendPage(currentPage);

            setCheckedItems({});
            setIsAllChecked(false);
        } catch (err) {
            console.error("일괄 상태 변경 에러:", err);
        }
    };

    const handleApprovalChange = async () => {

        try {
            const status = "2";  // 선택된 첫 항목의 상태를 기준으로 설정
            const response = await api.patch(`/api/m/allappstatus/${AppNo}/${status}`, null, { withCredentials: true });
            alert("상태가 성공적으로 일괄 업데이트되었습니다.");

            await dataSendPage(currentPage);

            setCheckedItems({});
            setIsAllChecked(false);
        } catch (err) {
            console.error("일괄 상태 변경 에러:", err);
        }
    };

    const handleCheckbtn = async () => {
        const selectedIds = Object.keys(checkedItems).filter(jointNo => checkedItems[jointNo]);

        if (selectedIds.length === 0) {
            alert("개별 상태 변경할 항목을 선택해주세요.");
            return;
        }

        try {
            await Promise.all(selectedIds.map(async (jointNo) => {
                const status = submitStatuses[jointNo] || "1";  // JointNo에 맞는 상태 가져오기
                const response = await api.patch(`/api/m/appstatus/${jointNo}/${status}`, null, { withCredentials: true });
                console.log(`JointNo: ${jointNo}, Status: ${status}`, response.data);
            }));
            alert("개별 상태가 성공적으로 업데이트되었습니다.");

            await dataSendPage(currentPage);

            setCheckedItems({});
        } catch (err) {
            console.error("개별 상태 변경 에러:", err);
        }
    };

    console.log("테이블 보기", tableData);

    return (
        <>
            <div className="adminpage-container">
                <div className="small-title">
                    <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                    <div className="adminhd-line"></div>
                    <h2 className="menu-name">관리자 메뉴</h2>
                </div>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <div className="menu-item">
                                    <Link to={item.path} style={{ display: 'block', width: '100%', height: '100%' }}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className="admin-under"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="admin-line"></div>
                    <div className="year-name">
                        <div className="submin-container">
                            <h3>신청서 관리 상세페이지</h3>
                            <div className="application-mngDetail">
                                <div>총 {listCount}건</div>
                                <div className="application-status-container">
                                    <button className="application-status" onClick={handleCheckbtn}>
                                        개별 상태 변경
                                    </button>
                                    <button className="dataStatus-allChange" onClick={handleReturnChange}>
                                        일괄 반려
                                    </button>
                                    <button className="dataStatus-allChange02" onClick={handleApprovalChange}>
                                        일괄 승인
                                    </button>
                                </div>
                            </div>
                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>
                                    {/*<input*/}
                                    {/*    type="checkbox"*/}
                                    {/*    className="row-checkboxSize"*/}
                                    {/*    checked={isAllChecked}*/}
                                    {/*    onChange={handleSelectAllChange}*/}
                                    {/*/>*/}
                                </th>
                                <th>Dmp정보</th>
                                <th>데이터세트</th>
                                <th>연책</th>
                                <th>DMP 삭제 여부</th>
                                <th>데이터 삭제 여부</th>
                                <th>유저정보 삭제 여부</th>
                                <th>상태정보</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData?.map((row, index) => (
                                <tr key={row.JointNo} className="dmplist-Data">
                                    <td>
                                        <input
                                            type="checkbox"
                                            className="row-checkboxSize"
                                            checked={checkedItems[row.JointNo] || false}
                                            onChange={(e) => handleCheckboxChange(e, row.JointNo)}
                                        />
                                    </td>
                                    <td>[{row.ProjNo}]<br/>{row.ProjNm}</td>

                                    <td>[{row.DtaSetNm}]{row.DtaNm}</td>
                                    <td>{row.RspnEmpNm}</td>
                                    <td>{row.DmpDelFlg}</td>
                                    <td>{row.DataDelFlg}</td>
                                    <td>{row.UserDelFlg}</td>
                                    <td>
                                        <select
                                            style={{ height: '37px', fontSize: '13px' }}
                                            value={submitStatuses[row.JointNo] || row.Status}
                                            onChange={(e) => handleStatusChange(e, row.JointNo)}
                                        >
                                            <option value="1">신청</option>
                                            <option value="2">승인</option>
                                            <option value="3">반려</option>
                                        </select>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ApplicationManagementDetail;
