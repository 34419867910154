import './App.css';
import React from "react";
import {Routes, Route, useLocation, Navigate} from "react-router-dom";
import HomePage from "./home/home";
import Header from "./header/header";
import Footer from './footer/footer';
import DmpcreatePage from "./dmpManagement/dmpCreate/dmpcreate";
import Dmplist from "./dmpManagement/dmpList/dmplist";
import Dmpsend from "./dmpManagement/dmpSend/dmpsend";
import DmpRealDataCreate from "./dmpRealData/dmpRealDataCreate/dmpRealDataCreate";
import DmpRealDataList from "./dmpRealData/dmpRealDataList/dmpRealDataList";
import DmpRealDataSend from "./dmpRealData/dmpRealDataSend/dmpRealDataSend";
import DataSubmitStatus from "./adminPage/submitStatus/dataSubmitStatus";
import DmpObjCreate from "./dmpobj/dmpObjCreate";
import DmpDataProductionPlan from "./dmpDataProductionPlan/dmpDataProductionPlan";
import AdminDataPage from "./adminPage/adminDataPage";
import DmpSubmitStatus from "./adminPage/dmpSubmitStatus/dmpSubmitStatus";
import DmpAccessCheck from "./adminPage/dmpAccessCheck/dmpAccessCheck";
import Login from "./login/login";
import OpenData from "./openData/openData";
import OpenDetailPage from "./openData/detailPage/detailPage";
import DmpRealDataModify from "./dmpRealData/dmpRealDataModify/dmpRealDataModify";
import DmpmodifyPage from "./dmpManagement/dmpModify";
import IntroPage from "./intro/intro";
import DmpBoard from "./board/board";
import DmpBoardDetail from "./board/boardDetail";
import {Helmet} from "react-helmet";
import DmpRealDataView from "./dmpRealData/dmpRealDataView/dmpRealDataView";
import DmpHisDataList from "./dmpRealData/dmpHisDataList/dmpHisDataList";
import DmpHisDataView from "./dmpRealData/dmpHisDataView/dmpHisDataView";
import DmpHisPageList from "./dmpManagement/dmpHisPageList/dmpHisPageList";
import DmpHisPageView from "./dmpManagement/dmpHisPageView/dmpHisPageView";
import OpenDataMyPage from "./myPage/myPage";
import DataSubmitList from "./adminPage/dataSubmitList/dataSubmitList";
import DataSubmitStatusChange from "./adminPage/dataSubmitStatusChange/dataSubmitStatusChange";
import DmpPlanStatus from "./adminPage/dmpPlanStatus/dmpPlanStatus";
import BoardWrite from "./board/write";
import ErrorPage from "./error/error";
import CurationList from "./Curation/list";
import UserManagement from "./adminPage/userManagement/userManagement";
import UserManagementDetail from "./adminPage/userManagement/detail";
import MypageCalendar from "./myPage/calendar";
import ApplicationManagementPage from "./adminPage/applicationManagement/applicationManagement";
import ProductionPlanMng from "./adminPage/productionPlanMng/productionPlanMng";
import ApplicationManagementDetail from "./adminPage/applicationManagement/detail";



// 로그인 여부를 확인하는 함수 (예: 로그인 상태를 확인하는 로직)
// const isAuthenticated = () => {
//     const authData = JSON.parse(localStorage.getItem('loginAuthStore') || '{}');
//     return authData.state?.userId !== null;
// };
//
// // PrivateRoute 컴포넌트
// function PrivateRoute({ children }) {
//     return isAuthenticated() ? children : <Navigate to="/login" />;
// }


function App() {

    const location = useLocation();


  return (
          <div className="allproject-container">
              <Helmet>
                  <title>KFRI Food Data Platform</title>
                  <link rel="icon" href="/public/favicon.ico"/>
              </Helmet>

              <Header /> {/* 조건에 맞지 않으면 Header 렌더링 */}
              <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/dmpManagement/dmpCreate" element={<DmpcreatePage />} />
                  <Route path="/dmpManagement/dmpList" element={<Dmplist />} />
                  <Route path="/dmpManagement/dmpSend" element={<Dmpsend />} />
                  <Route path="/openData" element={<OpenData />} />
                  <Route path="/openData/detailPage/:DtaSbmSeqNo" element={< OpenDetailPage/>} />
                  <Route path="/dmpRealData/dmpRealDataCreate" element={<DmpRealDataCreate />} />

                  {/*dmp 이력 리스트 코드*/}
                  <Route path="/dmpManagement/dmpHisPageList/:DmpNo" element={<DmpHisPageList />} />
                  <Route path="/dmpManagement/dmpHisPageView/:DmpHisNo" element={<DmpHisPageView />} />


                  <Route path="/dmpManagement/dmpModify/:id" element={<DmpmodifyPage />} />
                  <Route path="/dmpRealData/dmpRealDataView/:DtaSbmSeqNo" element={<DmpRealDataView />} />
                  <Route path="/dmpRealData/dmpRealDataModify/:DtaSbmSeqNo" element={<DmpRealDataModify />} />
                  <Route path="/dmpRealData/dmpRealDataList" element={<DmpRealDataList />} />
                  <Route path="/dmpRealData/dmpHisDataList/:DmpNo/:DtaSetNo" element={<DmpHisDataList />} />
                  <Route path="/dmpRealData/dmpHisDataView/:DmpNo/:DtaSetHisNo" element={<DmpHisDataView />} />

                  <Route path="/dmpRealData/dmpRealDataSend" element={<DmpRealDataSend />} />
                  <Route path="/dmpobj/dmpObjCreate" element={<DmpObjCreate />} />
                  <Route path="/dmpDataProductionPlan/dmpDataProductionPlan" element={<DmpDataProductionPlan />} />
                  <Route path="/login" element={<Login/>} />
                  <Route path="/intro" element={<IntroPage />} />
                  <Route path="/board/:BoardSeq" element={<DmpBoard />} />
                  <Route path="/board/boardDetail" element={<DmpBoardDetail />} />
                  <Route path="/board/write/:BoardSeq" element={<BoardWrite />} />

                  <Route path="/myPage" element={<OpenDataMyPage />} />
                  <Route path="/myPage/calendar" element={<MypageCalendar />} />


                  <Route path="/Curation/list" element={<CurationList />} />

                  {/*어드민 페이지*/}
                  <Route path="/adminPage" element={<AdminDataPage />} />
                  <Route path="/adminPage/submintStatus" element={<DataSubmitStatus />} />
                  <Route path="/adminPage/dataSubmitList" element={<DataSubmitList />} />
                  <Route path="/adminPage/dataSubmitStatusChange" element={<DataSubmitStatusChange />} />
                  <Route path="/adminPage/dmpSubmitStatus" element={<DmpSubmitStatus/>} />
                  <Route path="/adminPage/dmpPlanStatus" element={<DmpPlanStatus/>} />
                  <Route path="/adminPage/dmpAccessCheck" element={<DmpAccessCheck/>} />

                  {/*신청서 관리 페이지*/}
                  <Route path="/adminPage/applicationManagement" element={<ApplicationManagementPage/>} />
                  <Route path="/adminPage/applicationManagement/detail/:AppNo" element={<ApplicationManagementDetail/>} />
                  {/*생산 계획 누락*/}
                  <Route path="/adminPage/productionPlanMng" element={<ProductionPlanMng/>} />


                  {/*데이터 관리 계획(user별)*/}
                  <Route path="/adminPage/userManagement" element={<UserManagement/>} />
                  <Route path="/adminPage/userManagement/detail/:UserAuthSeq" element={<UserManagementDetail/>} />



                  {/* 모든 경로에 해당하지 않으면 ErrorPage로 이동 */}
                  <Route path="*" element={<ErrorPage />} />
              </Routes>
              {/*<Footer />*/}

              {location.pathname !== '/login' && <Footer />}
          </div>
  );
}

export default App;
