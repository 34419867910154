import React, { useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import '../submitStatus/dataSubmitStatus.css';
import api from "../../api";

function DataSubmitStatusChange() {

    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수
    const [listCount, setListCount] = useState(0); // 총 데이터 개수
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const [btnPage, setBtnPage] = useState(1);

    // 체크박스 상태
    const [checkedItems, setCheckedItems] = useState({});
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

    // 제출 상태
    const [submitStatuses, setSubmitStatuses] = useState({});

    const navigate = useNavigate();

    const dataSendPage = async (page = 1) => {
        let endPoint = `/api/m/datastatus/?ps=${page}&pl=${pageSize}`;
        try {
            const res = await api.get(endPoint, {
                withCredentials: true,
            });
            setTableData(res.data.item);
            setListCount(res.data.pages.Total); // 총 데이터 개수 설정
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
        } catch (error) {
            console.error('API 에러를 확인해주세요.', error);
        }
    };

    useEffect(() => {
        dataSendPage(currentPage);
    }, [currentPage]);

    // 페이지 버튼 처리
    const handerbtn = (pageNumber, i) => {
        setCurrentPage(pageNumber);
        setBtnPage(i);
    };

    // 페이지네이션 렌더링
    const renderPagination = () => {
        const startPage = Math.floor((btnPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            const pageNumber = (i - 1) * pageSize + 1;
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === btnPage ? 'active' : ''}`}
                    onClick={() => handerbtn(pageNumber, i)}
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setBtnPage(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setBtnPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };
    const adminMenu = [
        {
            'id' : 1,
            'name': "캘린더(공개시기 관리)",
            'path': "/adminPage"
        },
        {
            'id' : 2,
            'name': "데이터 제출 현황",
            'path': "/adminPage/submintStatus"
        },
        {
            'id' : 3,
            'name': "데이터 제출 목록 일괄",
            'path': "/adminPage/dataSubmitList"
        },
        {
            'id' : 4,
            'name': "데이터 제출 상태 변경",
            'path': "/adminPage/dataSubmitStatusChange"
        },
        {
            'id' : 5,
            'name': "DMP 작성 현황 및 제출 상태 변경",
            'path': "/adminPage/dmpSubmitStatus"
        },
        // {
        //     'id' : 6,
        //     'name': "DMP 계획 및 제출 현황",
        //     'path': "/adminPage/dmpPlanStatus"
        // },
        {
            'id' : 6,
            'name': "데이터 관리 계획(User별)",
            'path': "/adminPage/userManagement"
        },
        {
            'id' : 7,
            'name': "데이터관리 계획 접근권한(과제별)",
            'path': "/adminPage/dmpAccessCheck"
        },
        {
            'id' : 8,
            'name': "생산계획 누락현황 확인",
            'path': "/adminPage/productionPlanMng"
        },
        {
            'id' : 9,
            'name': "신청서 관리 페이지",
            'path': "/adminPage/applicationManagement"
        },
    ]

    // 전체 선택 핸들러
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setIsSelectAllChecked(isChecked);
        const newCheckedItems = {};
        tableData.forEach(row => {
            newCheckedItems[row.DtaSbmSeqNo] = isChecked;
        });
        setCheckedItems(newCheckedItems);
    };

    // 개별 체크박스 핸들러
    const handleCheckboxChange = (event, id) => {
        const isChecked = event.target.checked;
        setCheckedItems(prevState => ({
            ...prevState,
            [id]: isChecked,
        }));
    };

    // 제출 상태 변경 핸들러
    const handleStatusChange = (e, id) => {
        const newStatus = e.target.value;
        setSubmitStatuses(prevStatuses => ({
            ...prevStatuses,
            [id]: newStatus, // 해당 행의 상태만 변경
        }));
    };

    // 일괄 상태 변경 핸들러
    // 일괄 상태 변경 핸들러
    const handleBulkStatusChange = async () => {
        const selectedIds = Object.keys(checkedItems).filter(id => checkedItems[id]);

        // 선택된 체크박스가 없는 경우
        if (selectedIds.length === 0) {
            alert("해당 체크박스를 체크해주세요");
            return; // 상태 변경 실행을 중단
        }

        try {
            await Promise.all(selectedIds.map(async (id) => {
                const status = submitStatuses[id] || "1";
                const response = await api.patch(`/api/m/datasubmit/${id}/${status}`, null, { withCredentials: true });
                console.log(`ID: ${id}, Status: ${status}`, response.data);
            }));
            alert("상태가 성공적으로 업데이트되었습니다.");

            // 데이터 갱신
            await dataSendPage(currentPage);

            // 체크박스 초기화
            setCheckedItems({});
            setIsSelectAllChecked(false);

        } catch (err) {
            console.error("일괄 상태 변경 에러:", err);
        }
    };

    const handleRowClick = (DtaSbmSeqNo) => {
        navigate(`/dmpRealData/dmpRealDataView/${DtaSbmSeqNo}`);
    };

    return (
        <>
            <div className="adminpage-container">
                <div className="small-title">
                    <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                    <div className="adminhd-line"></div>
                    <h2 className="menu-name">관리자 메뉴</h2>
                </div>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <div className="menu-item">
                                    <Link to={item.path} style={{ display: 'block', width: '100%', height: '100%' }}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className="admin-under"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="admin-line"></div>
                    <div className="year-name">
                        <div className="submin-container">
                            <h3>데이터 제출 상태 변경</h3>
                            <div>총 {listCount}건</div>
                        </div>
                        <div className="status-btn04">
                            <h4>※ 당해연도 제출건만 조회됩니다.</h4>
                            <button className="dataStatus-allChange" onClick={handleBulkStatusChange}>
                                선택된 항목 상태 일괄 변경
                            </button>
                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>
                                    <input
                                        type="checkbox"
                                        className="row-checkboxSize"
                                        checked={isSelectAllChecked}
                                        onChange={handleSelectAllChange}
                                    />
                                </th>
                                <th>DtaSbmSeqNo</th>
                                <th>과제번호</th>
                                <th>과제수행년도</th>
                                <th>과제명</th>
                                <th>데이터세트</th>
                                <th>연책</th>
                                <th>제출자</th>
                                <th>제출상태</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData.map((row) => (
                                <tr key={row.DtaSbmSeqNo} className="dmplist-Data">
                                    <td>
                                        <input
                                            type="checkbox"
                                            className="row-checkboxSize"
                                            checked={checkedItems[row.DtaSbmSeqNo] || false}
                                            onChange={(e) => handleCheckboxChange(e, row.DtaSbmSeqNo)}
                                        />
                                    </td>
                                    <td>{row.DtaSbmSeqNo}</td>
                                    <td>{row.ProjNo}</td>
                                    <td>{row.AnnlYy}</td>
                                    <td>{row.ProjNm}</td>
                                    <td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(row.DtaSbmSeqNo)}>
                                        [{row.DtaSetNm}]
                                        {row.DtaNm} <br />
                                        {row.DtaDs}
                                    </td>
                                    <td>{row.RspnEmpNm}</td>
                                    <td>{row.UserNm}</td>
                                    <td>
                                        <select
                                            style={{height: '37px', fontSize: '13px'}}
                                            value={submitStatuses[row.DtaSbmSeqNo] || row.SubmitStatus} // 백엔드에서 받은 상태값 사용
                                            onChange={(e) => handleStatusChange(e, row.DtaSbmSeqNo)}
                                        >
                                            <option value="1">저장</option>
                                            <option value="2">제출</option>
                                            <option value="3">검토완료</option>
                                            <option value="4">검토완료(의견있음)</option>
                                        </select>

                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {renderPagination()}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DataSubmitStatusChange;
