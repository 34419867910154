import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../dmpManagement/dmpList/dmplist.css';
import api from '../api';

const BoardComponent = () => {
    const { BoardSeq } = useParams(); // URL 파라미터에서 BoardSeq 값을 가져옴
    const [boardValue, setBoardValue] = useState([]);
    const [boardName, setBoardName] = useState({});
    const [activeButton, setActiveButton] = useState('교육/세미나');
    const navigate = useNavigate();

    useEffect(() => {
        if (BoardSeq) {
            api.get(`/api/board/list?BoardSeq=${BoardSeq}`).then(res => {
                if (['1', '2', '3', '4'].includes(BoardSeq)) {
                    setBoardValue(res.data.list);
                }
            });
            api.get(`/api/board?BoardSeq=${BoardSeq}`).then(res => {
                if (['1', '2', '3', '4'].includes(BoardSeq)) {
                    setBoardName(res.data);
                }
            });
        }
    }, [BoardSeq]); // BoardSeq 값이 변경될 때마다 실행


    useEffect(() => {
        if (BoardSeq === '1') {
            setActiveButton('교육/세미나');
        } else if (BoardSeq === '2') {
            setActiveButton('교육/세미나');
        } else if (BoardSeq === '3') {
            setActiveButton('법령/정책자료');
        } else if (BoardSeq === '4') {
            setActiveButton('자료실');
        }
    }, [BoardSeq]);


    // Row 클릭 시 상세 페이지로 이동
    const handleRowClick = (BoardSeq, Seq) => {
        navigate(`/board/boardDetail?BoardSeq=${BoardSeq}&seq=${Seq}`);
    };

    // 버튼 클릭 시 해당 게시판으로 이동
    const handleButtonClick = (buttonLabel, newBoardSeq) => {
        setActiveButton(buttonLabel);
        navigate(`/board/${newBoardSeq}`);
    };

    // 새글 작성 페이지로 이동 (BoardSeq에 맞게)
    const newWriteHandler = () => {
        navigate(`/board/write/${BoardSeq}`, {
            state: { fromBoardSeq: BoardSeq }  // 현재 BoardSeq 값을 state로 전달
        });
    };

    console.log("boardValue", boardValue);

    return (
        <div className="dmpReallist-container">
            <div className="dmpReallist-banner-img">
                <img src={`${process.env.PUBLIC_URL}/sub-introimg01.jpg`} className="dmpReallist-img02" alt="search Logo" />
                <div className="banner-Realtext">
                    <span className="intro-subName">{boardName.BoardDesc}</span>
                </div>
                <div className="dmpReallist-description">
                    <div className="dmpReallist-description02">
                        <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                        <span> > 소개 > {boardName.BoardNm}</span>
                    </div>
                </div>
            </div>

            <div className="dmpReallist-inputbox"></div>

            {BoardSeq === '1' ? (
                <div style={{ height: '100vh' }}>
                    <div className="btn-Realcontainer">
                        {/* <div>총 {dataCount}건</div> */}
                    </div>
                    <div className="board-input-box">
                        <img
                            className="newimgCur"
                            src={`${process.env.PUBLIC_URL}/btn_newwrite.png`}
                            onClick={newWriteHandler}
                            alt="new write"
                        />
                    </div>
                    <div className="dmpReallist-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="dmpReallist-title">
                            <th>카테고리</th>
                            <th>제목</th>
                            <th>작성자</th>
                            <th>등록일</th>
                            <th>조회</th>
                        </tr>
                        </thead>
                        <tbody>
                        {boardValue?.map((row, index) => (
                            <tr className="dmpReallist-Data" key={index}>
                                <td>
                                    {row.BoardSeq === 1 ? "공지사항": ""}
                                </td>

                                <td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(row.BoardSeq, row.BoardListSeq)}>{row.Title}</td>
                                <td>{row.UserNm}</td>
                                <td className="boards-page">{row.InsDt}</td>
                                <td>{row.ReadCnt}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <>
                    <div className="dmpSeminarData">
                        <button
                            className={activeButton === '교육/세미나' ? 'active' : ''}
                            onClick={() => handleButtonClick('교육/세미나', 2)}
                        >
                            교육/세미나
                        </button>
                        <button
                            className={activeButton === '법령/정책자료' ? 'active' : ''}
                            onClick={() => handleButtonClick('법령/정책자료', 3)}
                        >
                            법령/정책자료
                        </button>
                        <button
                            className={activeButton === '자료실' ? 'active' : ''}
                            onClick={() => handleButtonClick('자료실', 4)}
                        >
                            자료실
                        </button>
                    </div>

                    <div style={{ height: '100vh' }}>
                        <div className="btn-Realcontainer">
                            {/* <div>총 {dataCount}건</div> */}
                        </div>
                        <div className="board-input-box">
                            <img
                                className="newimgCur"
                                src={`${process.env.PUBLIC_URL}/btn_newwrite.png`}
                                onClick={newWriteHandler}
                                alt="new write"
                            />
                        </div>
                        <div className="dmpReallist-start_line"></div>

                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmpReallist-title">
                                <th>카테고리</th>
                                <th>제목</th>
                                <th>작성자</th>
                                <th>등록일</th>
                                <th>조회</th>
                            </tr>
                            </thead>
                            <tbody>
                            {boardValue?.map((row, index) => (
                                <tr className="dmpReallist-Data" key={index}>
                                    <td>
                                        {row.BoardSeq === 2 ? "교육/세미나" : row.BoardSeq === 3 ? "법령/정책자료" : row.BoardSeq === 4 ? "자료실" : ""}
                                    </td>
                                    <td
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleRowClick(row.BoardSeq, row.BoardListSeq)}
                                    >
                                        {row.Title}
                                    </td>
                                    <td>{row.UserNm}</td>
                                    <td className="boards-page">{row.InsDt}</td>
                                    <td>{row.ReadCnt}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

export default BoardComponent;
