import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import 'moment/locale/ko';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './adminDataPage.css';
import { Link } from "react-router-dom";
import api from '../api';

function AdminDataPage() {
    const [myPageData, setMyPageData] = useState([]);
    const [events, setEvents] = useState([]);
    const [openDataList, setOpenDataList] = useState([]);
    const [btnPage, setBtnPage] = useState(1);
    const [selectedDate, setSelectedDate] = useState(new Date()); // 선택된 날짜 상태 추가
    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수 (1-10, 11-20 등)
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    // const [currentPage, setCurrentPage] = useState(1); // 현재 페이지

    moment.locale('ko');
    const localizer = momentLocalizer(moment);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await api.get(`/api/m/calendar`, { withCredentials: true });
                setMyPageData(res.data);

                const calendarEvents = res.data.map((item, index) => ([
                    {
                        id: `${item.DmpNo}-recorded-${index}`,
                        title: `${item.DtaNm} - 작성 날짜`,
                        start: new Date(item.OpnPd),
                        end: new Date(item.OpnPd),
                        type: 'recorded',
                        details: `내용: ${item.DtaNm}<br />작성 날짜: ${item.OpnPd}`,
                    },
                    {
                        id: `${item.DmpNo}-threeMonthsAgo-${index}`,
                        title: `${item.DtaNm} - 3달 전`,
                        start: new Date(item.OpnPdPrev3Months),
                        end: new Date(item.OpnPdPrev3Months),
                        type: 'threeMonthsAgo',
                        details: `내용: ${item.DtaNm}<br />3달 전 날짜: ${item.OpnPdPrev3Months}`,
                    }
                ])).flat();

                setEvents(calendarEvents);
            } catch (err) {
                console.error("API Error:", err);
            }
        };

        fetchData();
    }, []);

    const handleDateClick = async (date, page = 1) => {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        try {
            const res = await api.get(`/api/m/calendardatalist?Date=${formattedDate}&ps=${page}&pl=${pageSize}`, { withCredentials: true });
            setOpenDataList(res.data.item);
            setTotalPages(Math.ceil(res.data.total / pageSize)); // 전체 페이지 수 계산
            console.log("Selected Date Data:", res.data); // 선택한 날짜의 데이터 콘솔 출력
        } catch (err) {
            console.error("Error fetching data for selected date:", err);
        }
    };

    const handlePageClick = (pageNumber, i) => {
        // setCurrentPage(pageNumber);
        setBtnPage(i);
        handleDateClick(selectedDate, pageNumber); // 페이지 변경 시 선택한 날짜로 데이터 다시 불러오기
    };

    const renderPagination = () => {
        const startPage = Math.floor((btnPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            const pageNumber = i; // 각 페이지 번호
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === btnPage ? 'active' : ''}`}
                    onClick={() => handlePageClick(pageNumber, i)}
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setBtnPage(startPage - pageGroupSize)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setBtnPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    const adminMenu = [
        { 'id': 1, 'name': "캘린더(공개시기 관리)", 'path': "/adminPage" },
        { 'id': 2, 'name': "데이터 제출 현황", 'path': "/adminPage/submintStatus" },
        { 'id': 3, 'name': "데이터 제출 목록 일괄", 'path': "/adminPage/dataSubmitList" },
        { 'id': 4, 'name': "데이터 제출 상태 변경", 'path': "/adminPage/dataSubmitStatusChange" },
        { 'id': 5, 'name': "DMP 작성 현황 및 제출 상태 변경", 'path': "/adminPage/dmpSubmitStatus" },
        { 'id': 6, 'name': "데이터 관리 계획(User별)", 'path': "/adminPage/userManagement" },
        { 'id': 7, 'name': "데이터관리 계획 접근권한(과제별)", 'path': "/adminPage/dmpAccessCheck" },
        { 'id': 8, 'name': "생산계획 누락현황 확인", 'path': "/adminPage/productionPlanMng" },
        { 'id': 9, 'name': "신청서 관리 페이지", 'path': "/adminPage/applicationManagement" },
    ];

    // 상태 변경 함수
    const handleStatusChange = async (e, seq) => {
        const newStatus = e.target.value;

        // 로컬 상태 업데이트
        setOpenDataList(prevDataList =>
            prevDataList.map(item =>
                item.DtaSbmSeqNo === seq ? { ...item, OpenStatus: newStatus } : item
            )
        );

        try {
            // 서버에 상태 변경 요청 보내기
            await api.patch(`/api/m/openstatus/${seq}/${newStatus}`, {}, { withCredentials: true });
            alert("공개데이터 상태가 변경되었습니다.");
        } catch (err) {
            console.error("Error updating status:", err);
            alert("상태 변경에 실패했습니다.");
        }
    };

    console.log("openDataList", openDataList);

    return (
        <>
            <div className="adminpage-container">
                <div className="small-title">
                    <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                    <div className="adminhd-line"></div>
                    <h2 className="menu-name">관리자 메뉴</h2>
                </div>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item) => (
                            <React.Fragment key={item.id}>
                                <div className="menu-item">
                                    <Link to={item.path} style={{ display: 'block', width: '100%', height: '100%' }}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className="admin-under"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="admin-line"></div>
                    <div className="check-calendar">
                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 770 }}
                            selectable
                            onSelectSlot={(slotInfo) => {
                                setSelectedDate(slotInfo.start);
                                handleDateClick(slotInfo.start);
                            }}
                            eventPropGetter={(event) => {
                                let backgroundColor = event.type === 'recorded' ? '#898888' : '#ff6005';
                                return {
                                    style: {
                                        backgroundColor: backgroundColor,
                                        color: 'white',
                                    },
                                    'data-tooltip-id': `tooltip-${event.id}`,
                                    'data-tooltip-html': event.details,
                                };
                            }}
                            components={{
                                event: (props) => (
                                    <span
                                        data-tooltip-id={`tooltip-${props.event.id}`}
                                        data-tooltip-html={props.event.details}
                                    >
                                        {props.title}
                                    </span>
                                ),
                            }}
                        />
                        {events.map((event) => (
                            <Tooltip
                                key={event.id}
                                id={`tooltip-${event.id}`}
                                className="custom-tooltip"
                                place="top"
                                type="dark"
                                effect="float"
                                html={true}
                            />
                        ))}
                        <div>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                <tr className="dmplist-title">
                                    <th>과제명</th>
                                    <th>데이터명</th>
                                    <th>Dmp 삭제여부</th>
                                    <th>공개일자</th>
                                    <th>공개상태</th>
                                </tr>
                                </thead>
                                <tbody>
                                {openDataList?.map((row, index) => (
                                    <tr key={index} className="dmplist-Data">
                                        <td>[{row.ProjNo}]<br/>{row.ProjNm}</td>
                                        <td>{row.DtaNm}</td>
                                        <td>{row.DmpFlg}</td>
                                        <td>
                                            {row.OpnPd}
                                        </td>
                                        <td>
                                            <select
                                                style={{height: '37px', fontSize: '13px'}}
                                                value={row.OpenStatus} // openDataList의 값을 직접 사용
                                                onChange={(e) => handleStatusChange(e, row.DtaSbmSeqNo)}
                                            >
                                                <option value="0">비공개</option>
                                                <option value="1">공개</option>
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {renderPagination()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminDataPage;
