import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import api from "../api";

function BoardWrite() {

    const [files, setFiles] = useState([]);
    const [uploadImgFile, setUploadImgFile] = useState([]);
    const [sendImgFile, setSendImgFile] = useState([]);
    const [boardtitle, setBoardtitle] = useState("");
    const [boardcn, setBoardcn] = useState("");

    const { BoardSeq } = useParams();  // URL에서 BoardSeq를 가져옴
    const navigate = useNavigate();

    // 파일 업로드 처리 함수
    const handleUpload = async (file, index) => {
        if (files.length === 0) {
            alert('파일을 먼저 선택해주세요.');
            return;
        }
        const formData = new FormData();
        //board write에서 코드 확인 @@  ui 를 수정 페이지에서 업로드 버튼 하나로 수정
        files.forEach((file) => {
            formData.append('files', file);
        })
        formData.append('ct', "Board");

        try {
            const response = await api.post('/api/file/uploadfiles', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true
            });

            alert('업로드 완료 되었습니다.');
            setUploadImgFile(prev => [...prev, index]);
            setSendImgFile(prev => [...prev, ...response.data]); // 서버에서 받은 파일 정보 저장
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('파일이 업로드 되지 않았습니다.');
        }
    };

    // 파일 삭제 처리
    const closeFile = (indexRemoveFile) => {
        setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexRemoveFile));

        const removeUploadFile = sendImgFile[indexRemoveFile]?.SavFleNm;
        if (removeUploadFile) {
            api.post(`/api/file/deleteFile`, { SaveFile: removeUploadFile })
                .then(() => alert("업로드 된 파일 삭제!!"))
                .catch((error) => console.error("파일 삭제 에러:", error));
        }
    };

    // 파일 추가 처리
    const handleFileChange = (event) => {
        setFiles((prev) => [...prev, ...Array.from(event.target.files)]);
    };

    console.log("sendImgFilesendImgFile@@", sendImgFile);

    // 게시물 저장 처리
    const newBoardSave = async () => {
        if (!boardtitle || !boardcn) {
            alert("제목과 내용을 입력해주세요.");
            return;
        }

        const boardData = {
            BoardList: {
                BoardSeq: BoardSeq,  // URL에서 가져온 BoardSeq 값으로 설정
                Title: boardtitle,
                Contents: boardcn,
                // UserNo: "작성자 번호", // 필요 시 적절히 변경
                // UserNm: "작성자 이름",  // 필요 시 적절히 변경
                ReadCnt: 0,
                DelFlag: "N"
            },
            BoardListFile: sendImgFile.map(file => ({
                OrgFileNm: file.OriginalFileName,
                SavFleNm: file.SaveFile,
                FilePath: file.FilePath,
                FileSize: file.Size,
            }))
        };

        try {
            await api.post('/api/board/save', boardData, {
                withCredentials: true
            });
            alert('신규 게시물이 성공적으로 저장되었습니다.');
            navigate(`/board/${BoardSeq}`);  // 저장 후 해당 BoardSeq 페이지로 이동
        } catch (err) {
            console.error("게시물 저장 에러:", err);
            alert('게시물 저장 중 에러가 발생했습니다.');
        }
    };
    const newCancel = () => {
        navigate(-1);  // 이전 페이지로 이동
    }

    return (
        <>
            <div className="dmpRealData-banner-container">
                <div className="dmpRealData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg01.jpg`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="RealDatabanner-text">
                        <span className="intro-subName">새로운 소식을 알려드립니다.</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 소개> 공지사항 등록</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>공지사항 등록</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="board-write-title">
                        <div>
                            <span className="RealData-info">제목</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-task-title">
                            <input type="text" className="edit--box"
                                   onChange={(e) => setBoardtitle(e.target.value)}
                                   style={{width:'700px'}} placeholder="제목을 입력해주세요." />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="dataCreate-container">
                        <div className="dataCreate-title">
                            <span>본문</span>
                            <span className="required_color">*</span>
                        </div>
                        <textarea
                            type="text"
                            className="edit--box"
                            onChange={(e) => setBoardcn(e.target.value)}
                            style={{
                                width: '900px',
                                height: '400px',
                                padding: '10px',
                                fontSize: '16px',
                                lineHeight: '1.6',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f9f9f9',
                                whiteSpace: 'pre-wrap',
                            }}
                            placeholder="본문을 작성해주세요."
                        />
                    </div>
                    <div className="start_line"></div>
                    <div className="RealDataSend-title">
                        <div className="boardData-send">
                            <span>첨부 파일</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-sendFile-container">
                            {Array.isArray(files) && files.length > 0 && (
                            <>
                                    {files.map((item, index) => (
                                        <div className="creatFile-upload" key={index}>
                                            <div className="fileName">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                    alt="close"
                                                    onClick={() => closeFile(index)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <div>
                                                    <p>{item.name}</p> {/* 파일의 이름을 표시합니다 */}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                <div className="RealData-btnContainer">
                                    <button
                                        className="RealData-save-button"
                                        onClick={() => handleUpload(files)}>
                                        업로드
                                    </button>
                                </div>
                            </>
                            )}
                            <div>
                                <div className="research-method-title">
                                    <label htmlFor="fileDiagram">
                                        <img id="imgDiagram" src={`${process.env.PUBLIC_URL}/btn_file_select.png`} alt="Diagram" />
                                    </label>
                                </div>
                                <input type="file" id="fileDiagram" name="dataSubmitFiles" multiple onChange={handleFileChange} className="search-input" placeholder="파일 이름" />
                            </div>
                        </div>
                    </div>
                </section>

                <div className="RealData-save-btn-container">
                    <button id="dmp_list" onClick={newCancel}>
                        취소
                    </button>
                    <button id="save-btn" onClick={newBoardSave}>
                        저장
                    </button>
                </div>
            </div>
        </>
    );
}

export default BoardWrite;
