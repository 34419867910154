import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import api from "../api";

function DmpBoardDetail() {

    const navigate = useNavigate();
    const location = useLocation();
    const [files, setFiles] = useState([]);
    const [showCallImgData, setShowCallImgData] = useState([]);

    // URLSearchParams 객체를 사용하여 쿼리 파라미터 가져오기
    const queryParams = new URLSearchParams(location.search);
    const boardSeq = queryParams.get('BoardSeq'); // URL에서 BoardSeq 값 추출
    const seq = queryParams.get('seq'); // URL에서 seq 값 추출

    const [detailValue, setDetailValue] = useState({});
    const [boardFile, setBoardFile] = useState([]);
    const [boardName, setBoardName] = useState({});
    const [modifyTitle, setModifyTitle] = useState("");
    const [modifyCn, setModifyCn] = useState("");

    useEffect(() => {
        // 게시글 상세 정보 호출
        api.get(`/api/board/detail?BoardSeq=${boardSeq}&Seq=${seq}`).then(res => {
            setDetailValue(res.data.detail);
            setBoardFile(res.data.file);
            setModifyTitle(res.data.detail.Title);  // 제목 설정
            setModifyCn(res.data.detail.Contents);  // 본문 설정
        });

        // 게시판 이름 정보 호출
        api.get(`/api/board?BoardSeq=${boardSeq}`).then(res => {
            setBoardName(res.data);
        });
    }, [boardSeq, seq]);

    useEffect(() => {
        const updatedFileList = boardFile?.map(item => ({
            ...item,
            DtaFilePath: item.FilePath || null,
        }));
        setShowCallImgData(updatedFileList);
    }, [boardFile]);

    const handleFileChange = (event) => {
        if (Array.isArray(showCallImgData) && showCallImgData.length > 0) {
            // 기존 파일 목록에 새로운 파일들을 추가
            setShowCallImgData((prev) => [...prev, ...Array.from(event.target.files)]);
        } else {
            setFiles((prev)=> [...prev, ...Array.from(event.target.files)]);
        }
    };

    const handleUpload = async () => {
        if (files.length === 0 && showCallImgData.length === 0) {
            alert('파일을 먼저 선택해주세요.');
            return;
        }

        const formData = new FormData();

        // showCallImgData.forEach((file) => {
        //     formData.append('files', file);
        // });
        // formData.append('ct', "Board");

        const fileList = (files && files.length > 0) ? files : showCallImgData;

        if (fileList && fileList.length > 0) {
            fileList.forEach((file) => {
                formData.append('files', file);
            });
        }
        formData.append('ct', "Board");

        try {
            const response = await api.post('/api/file/uploadfiles', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true
            });
            alert('업로드 완료 되었습니다.');


            // type이 있는 파일들을 showCallImgData에서 제거
            const updatedShowCallImgData = showCallImgData.filter((item) => !item.type);

            // response.data에 있는 파일을 새로운 형식으로 변환하여 추가
            const newFiles = response.data.map((file) => ({
                ...file,
                Fstatus: "save",
                BoardListSeq : boardFile.BoardListSeq,
            }));


            // 상태 업데이트: type이 없는 기존 파일들 + 새로운 파일들
            setShowCallImgData([...updatedShowCallImgData, ...newFiles]);

        } catch (error) {
            console.error('Error uploading file:', error);
            alert('이미 업로드가 완료된 파일입니다.');
        }
    };

    useEffect(() => {
    }, [showCallImgData]);

    const closeFile = (indexRemoveFile) => {
        setShowCallImgData((prevFiles) =>
            prevFiles.map((item, index) => {
                if (index === indexRemoveFile) {
                    return {
                        ...item,
                        Fstatus: "delete", // 삭제 상태 추가
                        BoardListSeq : boardFile.BoardListSeq,
                    };
                }
                return item;
            })
        );
        console.log("삭제 후 데이터 확인 ", showCallImgData);
        alert("삭제되었습니다.")
    };

    //삭제 이후 데이터 제출 렌더링
    useEffect(() => {
    }, [showCallImgData]);

    // const boardModifyHandler = async () => {
    //     const callSaveFileList = showCallImgData.map((item) => ({
    //         BoardListFileSeq: item.BoardListFileSeq,
    //         BoardListSeq: detailValue.BoardListSeq,
    //         FilePath: item.FilePath,
    //         OrgFileNm: item.OrgFileNm || item.OriginalFileName,
    //         SavFleNm: item.SavFleNm || item.SaveFile,
    //         FileSize: item.FileSize || item.Size,
    //         Fstatus: item.Fstatus,
    //     }));
    //
    //     const modifiedData = {
    //         BoardList: {
    //             BoardSeq: boardSeq,
    //             BoardListSeq: detailValue.BoardListSeq,
    //             Title: modifyTitle,
    //             Contents: modifyCn,
    //             ReadCnt : 0,
    //         },
    //         BoardListFile: callSaveFileList,
    //     };
    //
    //     try {
    //         await api.put(`/api/board/modify`, modifiedData, {
    //             withCredentials: true
    //         });
    //
    //         alert('수정이 완료되었습니다.');
    //         navigate(`/board/${boardSeq}`);  // 수정 후 해당 BoardSeq 리스트 페이지로 이동
    //     } catch (err) {
    //         console.error("수정 에러:", err);
    //         alert('수정 중 에러가 발생했습니다.');
    //     }
    // };

    console.log("showCallImgDatashowCallImgData", showCallImgData);

    const boardModifyHandler = async () => {
        // 파일 리스트를 생성하는 코드
        const callSaveFileList = showCallImgData.map((item) => {
            if (item.Fstatus === "save") {
                return {
                    BoardListFileSeq: item.BoardListFileSeq,
                    BoardListSeq: detailValue.BoardListSeq,
                    FilePath: item.FilePath,
                    OrgFileNm: item.OrgFileNm || item.OriginalFileName,
                    SavFleNm: item.SavFleNm || item.SaveFile,
                    FileSize: item.FileSize || item.Size,
                    Fstatus: item.Fstatus,
                };
            } else if (item.Fstatus === "delete") {
                return {
                    BoardListFileSeq: item.BoardListFileSeq,
                    BoardListSeq: detailValue.BoardListSeq,
                    FilePath: item.FilePath,
                    OrgFileNm: item.OrgFileNm,
                    SavFleNm: item.SavFleNm || item.SaveFile,
                    FileSize: item.FileSize || item.Size,
                    Fstatus: "delete", // 삭제 상태
                };
            } else {
                return {
                    BoardListFileSeq: item.BoardListFileSeq,
                    BoardListSeq: detailValue.BoardListSeq,
                    FilePath: item.FilePath,
                    OrgFileNm: item.OrgFileNm || item.OriginalFileName,
                    SavFleNm: item.SavFleNm || item.SaveFile,
                    FileSize: item.FileSize || item.Size,
                    Fstatus: "none", // 기본 상태
                };
            }
        });


        // 수정된 데이터 구조 생성
        const modifiedData = {
            BoardList: {
                BoardSeq: boardSeq,
                BoardListSeq: detailValue.BoardListSeq,
                Title: modifyTitle,
                Contents: modifyCn,
                ReadCnt: 0, // 초기화된 조회수
            },
            BoardListFile: callSaveFileList, // 파일 정보 리스트
        };

        try {
            // 서버에 PUT 요청 전송
            await api.put(`/api/board/modify`, modifiedData, {
                withCredentials: true
            });

            // 성공 알림 및 이동
            alert('수정이 완료되었습니다.');
            navigate(`/board/${boardSeq}`);  // 수정 후 해당 BoardSeq 리스트 페이지로 이동
        } catch (err) {
            console.error("수정 에러:", err);
            alert('수정 중 에러가 발생했습니다.');
        }
    };


    const boardRemoveHandler = async () => {



        try {
            const res = await api.delete(`/api/board/board/${seq}`);
            if (res.data === 'Y') {
                alert("해당 공지사항이 삭제되었습니다.");
                navigate(`/board/${boardSeq}`);
            }
        } catch (err) {
            console.log(err);
            alert("삭제 중 오류가 발생했습니다.");
        }
    };



    console.log("showCallImgData", showCallImgData);

    return (
        <>
            <div className="dmpRealData-banner-container">
                <div className="dmpRealData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpRealData-img02" alt="search Logo" />
                    <div className="RealDatabanner-text">
                        <span>{boardName.BoardDesc}</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 소개> {boardName.BoardNm}</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>게시글 상세</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="RealData-object-info">
                        <div className="RealData-object-info-title" style={{width: '140px'}}>
                            <span className="RealData-info">제목</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-task-title">
                            <input
                                type="text"
                                className="edit--box"
                                style={{width:'700px'}}
                                value={modifyTitle}
                                onChange={(e) => setModifyTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="dataCreate-container">
                        <div className="dataCreate-title">
                            <span>본문</span>
                            <span className="required_color">*</span>
                        </div>
                        <textarea
                            className="edit--box"
                            style={{width: '900px', height: '400px', padding: '10px', fontSize: '16px'}}
                            value={modifyCn}
                            onChange={(e) => setModifyCn(e.target.value)}
                        />
                    </div>
                    <div className="start_line"></div>
                    <div className="RealDataSend-title">
                        <div className="boardData-send">
                            <span>첨부파일</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="modify-sendFile-container">
                            {Array.isArray(files) && files.length > 0 ? (
                                files.map((item, index) => (
                                    <div className="fileName" key={index}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                            alt="close"
                                            onClick={() => closeFile(index)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <div>
                                            <p>{item.name}</p> {/* 파일의 이름을 표시합니다 */}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                Array.isArray(showCallImgData) && showCallImgData.length > 0 &&
                                showCallImgData.map((item, index) => {
                                    if (item.Fstatus === "delete") {
                                        // 삭제된 항목은 렌더링하지 않음
                                        return null;
                                    } else if (item.Fstatus === "save") {
                                        // 저장된 항목은 저장된 상태로 렌더링
                                        return (
                                            <div className="fileName" key={index}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                    alt="close"
                                                    onClick={() => closeFile(index)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <div>
                                                    <p>{item.OriginalFileName}</p> {/* 파일의 이름을 표시합니다 */}
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        // "Fstatus" 값이 없거나 다른 경우 기본 렌더링
                                        return (
                                            <div className="fileName" key={index}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                    alt="close"
                                                    onClick={() => closeFile(index)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <div>
                                                    <p>{item.OrgFileNm || item.name}</p>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            )}

                            {/* 업로드 버튼은 파일 목록이 렌더링된 후 한 번만 나타나도록 아래에 위치 */}
                            {(Array.isArray(files) && files.length > 0) ||
                            (Array.isArray(showCallImgData) && showCallImgData.some(item => item.Fstatus !== "delete")) ? (
                                <div className="RealData-btnContainer">
                                    <button className="RealData-save-button" onClick={handleUpload}>
                                        업로드
                                    </button>
                                </div>
                            ) : null}

                            <div className="research-method-title">
                                <label htmlFor="fileDiagram">
                                    <img id="imgDiagram" src={`${process.env.PUBLIC_URL}/btn_file_select.png`} alt="Diagram" />
                                </label>
                            </div>
                            <input
                                type="file"
                                id="fileDiagram"
                                name="dataSubmitFiles"
                                multiple
                                onChange={handleFileChange}
                                className="search-input"
                                placeholder="파일 이름"
                            />
                        </div>

                    </div>
                </section>

                <div className="RealData-save-btn-container">
                    <button id="dmp_list">
                        <Link to={`/board/${boardSeq}`}>목록</Link>
                    </button>
                    <button id="save-btn"  onClick={boardRemoveHandler}>
                        삭제
                    </button>
                    <button id="save-btn" onClick={boardModifyHandler} >
                        수정
                    </button>
                </div>
            </div>
        </>
    );
}

export default DmpBoardDetail;
