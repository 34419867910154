import React, {useState, useEffect} from "react";
import '../dmpRealDataCreate/dmpRealDataCreate.css';
import {Link, useNavigate} from "react-router-dom";
import ToastGrid from "../../toast/toastGrid";
import RegisterDmp from "../../registerDmp/registerDmp";
import {
    useRegisterDataStore,
    useToastSetStore,
    useToastSetResultStore, useToastSetMethodStore
} from "../../stores/lastDmpData";
import ImportDataSet from "../../importDataSet/importDataSet";
import axios from "axios";
import {
    useDataSetsToastStore,
    useDataSummaryToastFilterStore,
    useDataSummaryToastStore
} from "../../stores/dataSetsStore";
import { useParams } from 'react-router-dom';
import api from "../../api";

function DmpRealDataModify() {

    const navigate = useNavigate();

    //use파람 값 확인
    const { DtaSbmSeqNo } = useParams();

    // 상태값
    const [files, setFiles] = useState([]);
    const [showCallImgData, setShowCallImgData] = useState([]);
    const [dataPageSummaryData, setDataPageSummaryData] = useState([]);

    //등록 클릭 시 crate를 해줘야함 ~
    const [callImgData, setCallImgData] = useState({});

    useEffect(() => {
        api.get(`/api/data/detail?DtaSbmSeqNo=${DtaSbmSeqNo}`)
            .then((res) => setCallImgData(res.data))
            .catch((err) => console.error("API Error:", err));
    }, []);

    console.log("callImgDatacallImgDa222ta@", callImgData);

    const [showRegisterDmpCheck, setShowRegisterDmpCheck] = useState(false);
    const [showImportData, setShowImportData] = useState(false);


    // 여기서 부터 신규 데이터 등록 변수 정리=====================================================
    // 등록 DMP 불러오기
    const registerdataSets = useRegisterDataStore(state =>state.registerdataSets);


    //DMP 데이터 세트(Summary)를 말함 불러오기
    const callSummaryFilterDataSets = useDataSummaryToastFilterStore(state => state.callSummaryFilterDataSets);

    useEffect(() => {
        setDataPageSummaryData(callSummaryFilterDataSets);
    },[callSummaryFilterDataSets])


    //데이터 세트 변경 변수 선언
    const DataPageMatarialDataSets = useToastSetStore(state => state.toastdataSets);
    const DataPageMethodDataSets = useToastSetMethodStore(state => state.toastMethoddataSets);
    const DataPageResultDataSets = useToastSetResultStore(state => state.toastResultdataSets);


    console.log("신규 데이터 세트 확인", DataPageResultDataSets, DataPageMethodDataSets, DataPageMatarialDataSets);

    //파일을 불러온 후 가공 === 불러오기단계임
    useEffect(() => {

        // 각 파일에 대해 DtaFilePath 추출
        const updatedFileList = callImgData.file?.map(item => {
            const decodedPath = decodeURIComponent(item.DtaSaveFileNm);
            const startIndex = decodedPath.indexOf("C:"); // 파일 경로의 시작 인덱스
            const DtaFilePath = startIndex !== -1 ? decodedPath.substring(startIndex) : null;

            return {
                ...item,
                DtaFilePath: DtaFilePath
            };
        });


        // 상태 업데이트
        setShowCallImgData(updatedFileList);
    }, [callImgData]); // callImgData가 변경될 때만 실행

    const toggleObjCheck = () => {
            setShowRegisterDmpCheck(showRegisterDmpCheck);
            alert("수정에선 불러오기가 불가능합니다.");
    }

    const toggleImportDataSets = () => {
            setShowImportData(showImportData);
            alert("수정에선 데이터세트 불러오기가 불가능합니다.");
    }


    //신규 데이터 등록에 필요한 로직
    const handleFileChange = (event) => {
        if (Array.isArray(showCallImgData) && showCallImgData.length > 0) {
            // 기존 파일 목록에 새로운 파일들을 추가
            setShowCallImgData((prev) => [...prev, ...Array.from(event.target.files)]);
        } else {
            setFiles((prev)=> [...prev, ...Array.from(event.target.files)]);
        }
    };


    const handleUpload = async () => {
        if (files.length === 0 && showCallImgData.length === 0) {
            alert('파일을 먼저 선택해주세요.');
            return;
        }

            const formData = new FormData();

            // 실제 파일 데이터를 FormData에 추가
         const fileList = (files && files.length > 0) ? files : showCallImgData;

            if (fileList && fileList.length > 0) {
                fileList.forEach((file) => {
                    formData.append('files', file);
                });
            }

        formData.append('ct', "Data");


        console.log("제 파일 데이터를 FormData에 추가", showCallImgData, files);

        console.log("제 파일 데이터를 FormData에 추가formData", formData);


            try {
                const response = await api.post('/api/file/uploadfiles', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // 필요한 경우 추가 설정
                    },
                    withCredentials: true
                });
                alert('업로드 완료 되었습니다.');

                // type이 있는 파일들을 showCallImgData에서 제거
                const updatedShowCallImgData = showCallImgData.filter((item) => !item.type);

                // response.data에 있는 파일을 새로운 형식으로 변환하여 추가
                const newFiles = response.data.map((file) => ({
                    ...file,
                    Fstatus: "save",
                    DtaSbmSeqNo : callImgData.dmpSummary.DtaSbmSeqNo,
                }));


                // 상태 업데이트: type이 없는 기존 파일들 + 새로운 파일들
                setShowCallImgData([...updatedShowCallImgData, ...newFiles]);


            } catch (error) {
                console.error('Error uploading file:', error);
                alert('이미 업로드가 완료된 파일입니다.');
            }
    };
    // 상태 업데이트 후 로그를 확인하기 위해 useEffect 사용
    useEffect(() => {
    }, [showCallImgData]);

    const closeFile = (indexRemoveFile) => {

            // 파일을 물리적으로 삭제하지 않고 상태를 업데이트
            setShowCallImgData((prevFiles) =>
                prevFiles.map((item, index) => {
                    if (index === indexRemoveFile) {
                        return {
                            ...item,
                            Fstatus: "delete", // 삭제 상태 추가
                            DtaSbmSeqNo : callImgData.dmpSummary.DtaSbmSeqNo,
                        };
                    }
                    return item;
                })
            );
            console.log("삭제 후 데이터 확인 ", showCallImgData);
            alert("삭제되었습니다.")
    }

    //삭제 이후 데이터 제출 렌더링
    useEffect(() => {
    }, [showCallImgData]);


    const getSelectedDataSets = (DtaSetNm) => {
        console.log("필터에 걸리는 값:", DtaSetNm);  // DtaSetNm 값 로그 추가

        if (DtaSetNm === '시료데이터') {
            return DataPageMatarialDataSets;
        } else if (DtaSetNm === '방법데이터') {
            return DataPageMethodDataSets;
        } else if (DtaSetNm === '결과데이터') {
            return DataPageResultDataSets;
        }
        return [];
    };

    const modifySummaryDataSets = () => {
        if (callImgData && callImgData.dmpSummary) {
            return getSelectedDataSets(callImgData.dmpSummary.DtaSetNm);
        }
        return []; // Return an empty array if no valid data is found
    };


    console.log("수정데이터showCallImgDatashowCallImgDatashowCallImgData 확인",showCallImgData)

    const viewListHandler = () =>{
        navigate('/dmpRealData/dmpRealDataList');
    }

    const TemporaryDatabtn = async () => {
        // 업데이트 로직
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file);
        });
        formData.append('ct', "Data");

        // 파일 리스트를 생성하는 코드
        const callSaveFileList = (showCallImgData && showCallImgData.length > 0) ? showCallImgData.map((item) => {
            if (item.Fstatus === "save") {
                return {
                    DtaSbmSeqNo: item.DtaSbmSeqNo,
                    DtaFilePath: item.FilePath || null,
                    DtaFileNm: item.OriginalFileName || null,
                    DtaSaveFileNm: item.SaveFile || null,
                    DtaFileSize: item.Size || null,
                    Fstatus: item.Fstatus,
                };
            } else if (item.Fstatus === "delete") {
                return {
                    FileSeqNo: item.FileSeqNo,
                    DtaFilePath: callImgData?.file?.[0]?.DtaFilePath || null,
                    DtaFileNm: item.DtaFileNm || null,
                    DtaSaveFileNm: item.DtaSaveFileNm || null,
                    DtaFileSize: Number(item.DtaFileSize) || null,
                    Fstatus: item.Fstatus,
                };
            } else {
                return {
                    FileSeqNo: item.FileSeqNo,
                    DtaFilePath: callImgData?.file?.[0]?.DtaFilePath || null,
                    DtaFileNm: item.DtaFileNm || null,
                    DtaSaveFileNm: item.DtaSaveFileNm || null,
                    DtaFileSize: Number(item.DtaFileSize) || null,
                    Fstatus: 'none',
                };
            }
        }) : [{
            FileSeqNo: null,
            DtaFilePath: null,
            DtaFileNm: null,
            DtaSaveFileNm: null,
            DtaFileSize: null,
            Fstatus: 'none',
        }];


        let resultModifyDataSets = callImgData.data.map((item) => ({
            DtaSetNo: item.DtaSetNo,
            DmpNo: item.DmpNo,
            DtaFld: item.DtaFld,
            DtaTyp: item.DtaTyp,
            DtaUnit: item.DtaUnit,
            DtaMtdIdx: item.DtaMtdIdx,
            DtaMtdNm: item.DtaMtdNm,
            DtaDsc: item.DtaDsc,
            DelFlg: "N"
        }));

        const addDataSets = modifySummaryDataSets(); // updateSummaryDataSets 호출 결과를 변수에 저장

        if (addDataSets.length > 0) {
            resultModifyDataSets = addDataSets.map((item) => ({
                DtaSetNo: item.DtaSetNo,
                DmpNo: item.DmpNo,
                DtaFld: item.field,
                DtaTyp: item.type,
                DtaUnit: item.unit,
                DtaMtdIdx: item.index,
                DtaMtdNm: item.name,
                DtaDsc: item.methoddescription || item.description,
                DelFlg: "N"
            }));
        }

        try {
            const response = await api.post('/api/data/modifydata', {
                Summary: {
                    DtaSetNo: callImgData.dmpSummary?.DtaSetNo,
                    DmpNo: callImgData.dmpSummary.DmpNo,
                    DtaSetNm: callImgData.dmpSummary?.DtaSetNm,
                    DtaSetNmSub: callImgData.dmpSummary?.DtaSetNmSub,
                    DtaNm: callImgData.dmpSummary?.DtaNm,
                    DtaDs: callImgData.dmpSummary?.DtaDs,
                    DmpFlg: callImgData.dmpSummary?.DmpFlg
                },
                DataMain: resultModifyDataSets,
                DataSubmit: {
                    DtaSbmSeqNo: callImgData.dmpSummary.DtaSbmSeqNo,
                    SubmitStatus: '0'
                },
                DataSubmitFile: callSaveFileList,
                TmpYn: "Y", // 임시 저장임을 표시
            }, { withCredentials: true }).then((res) => res.data);

            alert('임시 저장이 완료되었습니다.');
            navigate("/dmpRealData/dmpRealDataList");
        } catch (error) {
            console.error('Upload error:', error);
            alert('임시 저장 api를 확인해보세요.');
        }
    };



    const realDataSave = async () => {
        //업데이트 로직
            const formData = new FormData();

            files.forEach((file) => {
                formData.append('files', file);
            });
                formData.append('ct', "Data");

            // 파일 리스트를 생성하는 코드
            const callSaveFileList = showCallImgData.map((item) => {
                if(item.Fstatus === "save") {
                    return {
                        DtaSbmSeqNo: item.DtaSbmSeqNo,
                        DtaFilePath:item.FilePath,
                        DtaFileNm: item.OriginalFileName,
                        DtaSaveFileNm: item.SaveFile,
                        DtaFileSize: item.Size,
                        Fstatus: item.Fstatus,
                    };
                } else if(item.Fstatus === "delete") {
                    return {
                        FileSeqNo: item.FileSeqNo,
                        DtaFilePath:callImgData.file[0].DtaFilePath,
                        DtaFileNm: item.DtaFileNm,
                        DtaSaveFileNm: item.DtaSaveFileNm,
                        DtaFileSize: Number(item.DtaFileSize),
                        Fstatus: item.Fstatus,
                    };
                }
                else {
                    return {
                        FileSeqNo: item.FileSeqNo,
                        DtaFilePath:callImgData.file[0].DtaFilePath,
                        DtaFileNm: item.DtaFileNm,
                        DtaSaveFileNm: item.DtaSaveFileNm,
                        DtaFileSize: Number(item.DtaFileSize),
                        Fstatus: 'none',
                    };
                }
            });

        let resultModifyDataSets = callImgData.data.map((item) => ({
            DtaSetNo: item.DtaSetNo,
            DmpNo: item.DmpNo,
            DtaFld: item.DtaFld,
            DtaTyp: item.DtaTyp,
            DtaUnit: item.DtaUnit,
            DtaMtdIdx: item.DtaMtdIdx,
            DtaMtdNm: item.DtaMtdNm,
            DtaDsc: item.DtaDsc,
            DelFlg: "N"
        }));

        const addDataSets = modifySummaryDataSets(); // updateSummaryDataSets 호출 결과를 변수에 저장

        if(addDataSets.length > 0) {
            resultModifyDataSets = addDataSets.map((item) => ({
                DtaSetNo: item.DtaSetNo,
                DmpNo: item.DmpNo,
                DtaFld: item.field,
                DtaTyp: item.type,
                DtaUnit: item.unit,
                DtaMtdIdx: item.index,
                DtaMtdNm: item.name,
                DtaDsc: item.methoddescription || item.description,
                DelFlg: "N"
            }));
        }

            try {
                const response = await api.post('/api/data/modifydata', {
                    Summary:
                        {
                            DtaSetNo : callImgData.dmpSummary?.DtaSetNo,
                            DmpNo: callImgData.dmpSummary.DmpNo,
                            DtaSetNm: callImgData.dmpSummary?.DtaSetNm,
                            DtaSetNmSub : callImgData.dmpSummary?.DtaSetNmSub,
                            DtaNm: callImgData.dmpSummary?.DtaNm,
                            DtaDs : callImgData.dmpSummary?.DtaDs,
                            DmpFlg : callImgData.dmpSummary?.DmpFlg
                        },
                    DataMain:
                    resultModifyDataSets,

                    DataSubmit:
                        {
                            DtaSbmSeqNo : callImgData.dmpSummary.DtaSbmSeqNo,
                            SubmitStatus: '1'
                        },

                    DataSubmitFile:
                    callSaveFileList,
                    TmpYn: "N",

                },{ withCredentials: true}).then((res) => res.data);
                alert('수정 성공했습니다.');
                navigate("/dmpRealData/dmpRealDataList");
            } catch (error) {
                console.error('Upload error:', error);
                alert('수정 api를 확인해보세요.');
            }
    };

    return(
        <>
            <div className="dmpRealData-banner-container">
                <div className="dmpRealData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg03.jpg`} className="dmpRealData-img02" alt="search Logo" />
                    <div className="RealDatabanner-text">
                        <span className="intro-subName">데이터 수정</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터> 데이터 수정</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>데이터 수정</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="RealData-object-info">
                        <div className="RealData-object-info-title">
                            <span className="RealData-info">DMP 정보</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-task-title">
                            <div className="RealData-task-wrapper">
                                <button className="Real-check-obj" onClick={toggleObjCheck}>등록 DMP 불러오기</button>
                                {showRegisterDmpCheck && <RegisterDmp/>}

                                {
                                        DtaSbmSeqNo && (
                                            <>
                                                <input type="text" className="edit--box" readOnly placeholder="과제번호" value={callImgData.dmpSummary?.ProjNo}/>
                                                <input type="text" className="edit--box" readOnly placeholder="과제명" value={callImgData.dmpSummary?.ProjNm}/>
                                                <input type="text" className="edit--box" readOnly placeholder="연구책임자" value={callImgData.dmpSummary?.RspnEmpNm}/>
                                            </>
                                        )
                                }
                                {/*<button className="Real-check-obj" >DMP 보기</button>*/}
                            </div>
                            <div>
                                <span className="tast-des">* 등록된 DMP를 선택하면 DMP 정보가 자동으로 입력됩니다.</span>
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="dataCreate-container">
                        <div className="dataCreate-title">
                            <span>등록 대상 데이터</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <div className="dmpDataSets-container">
                                { showImportData && <ImportDataSet DtaSetNo={registerdataSets?.dmpMain?.DtaSetNo} /> }
                                {
                                        DtaSbmSeqNo && (
                                            <>
                                                <div className="dataSets-Inputboxs">
                                                    <button className="dataSet-bring" onClick={toggleImportDataSets}>DMP 데이터세트 불러오기</button>
                                                    <div className="selectbox-container">
                                                        <div className="DataPage-selectbox-check">
                                                            <select
                                                                className="createDataPage"
                                                                value={callImgData.dmpSummary?.DtaSetNm || ''}
                                                                readonly
                                                            >
                                                                <option value=''>-- 구분 --</option>
                                                                <option value="시료데이터">시료데이터</option>
                                                                <option value="방법데이터">방법데이터</option>
                                                                <option value="결과데이터">결과데이터</option>
                                                            </select>
                                                            <select
                                                                className="createDataPage"
                                                                id="selectBox"
                                                                value={callImgData.dmpSummary?.DtaSetNmSub || ''}
                                                                readonly
                                                            >
                                                                <option value=''>-- 선택 * 필수 --</option>
                                                                <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                                <option value="Food, Processed">Food, Processed</option>
                                                                <option value="Food, Derived">Food, Derived</option>
                                                                <option value="Animal">Animal</option>
                                                                <option value="Human">Human</option>
                                                                <option value="Invertebrate">Invertebrate</option>
                                                                <option value="Microbe">Microbe</option>
                                                                <option value="Cell, Animal">Cell, Animal</option>
                                                                <option value="Cell, Human">Cell, Human</option>
                                                                <option value="Cell, Plant">Cell, Plant</option>
                                                                <option value="etc">etc</option>
                                                            </select>

                                                            <input
                                                                type="text"
                                                                className="edit--box"
                                                                id="data-type-name"
                                                                name="DtaNm"
                                                                placeholder="데이터 타입명"
                                                                value={callImgData.dmpSummary?.DtaNm || ''}
                                                                readonly
                                                            />
                                                            <input type="text"
                                                                   className="edit--box"
                                                                   id="data-type-desc"
                                                                   name="DtaDs"
                                                                   placeholder="데이터 타입 설명"
                                                                   value={callImgData.dmpSummary?.DtaDs || ''}
                                                                   readonly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="datePage-targetData-des">
                                                    <span className="tast-des">* 등록된 DMP 데이터세트를 선택하면 데이터세트가 자동으로 입력됩니다.</span>
                                                    <span className="tast-des">* TYPE은 문자열:"STRING", 숫자:"NUMERIC", 날짜:"DATE", 파일명:"FILE" 로 사용을 권장합니다.</span>
                                                    <ToastGrid MethodData={callImgData.dmpSummary?.DtaSetNm} ModifySets={callImgData.data} SubmitStatus={callImgData.dmpSummary?.SubmitStatus}/>
                                                </div>
                                            </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="RealDataSend-title">
                        <div className="RealData-send">
                            <span>데이터 제출</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="modify-sendFile-container">
                            {Array.isArray(files) && files.length > 0 ? (
                                files.map((item, index) => (
                                    <div className="fileName" key={index}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                            alt="close"
                                            onClick={() => closeFile(index)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <div>
                                            <p>{item.name}</p> {/* 파일의 이름을 표시합니다 */}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                Array.isArray(showCallImgData) && showCallImgData.length > 0 &&
                                showCallImgData.map((item, index) => {
                                    if (item.Fstatus === "delete") {
                                        // 삭제된 항목은 렌더링하지 않음
                                        return null;
                                    } else if (item.Fstatus === "save") {
                                        // 저장된 항목은 저장된 상태로 렌더링
                                        return (
                                            <div className="fileName" key={index}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                    alt="close"
                                                    onClick={() => closeFile(index)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <div>
                                                    <p>{item.OriginalFileName}</p> {/* 파일의 이름을 표시합니다 */}
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        // "Fstatus" 값이 없거나 다른 경우 기본 렌더링
                                        return (
                                            <div className="fileName" key={index}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                    alt="close"
                                                    onClick={() => closeFile(index)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <div>
                                                    <p>{item.name || item.DtaFileNm}</p> {/* 파일의 이름을 표시합니다 */}
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            )}

                            {/* 업로드 버튼은 파일 목록이 렌더링된 후 한 번만 나타나도록 아래에 위치 */}
                            {(Array.isArray(files) && files.length > 0) ||
                            (Array.isArray(showCallImgData) && showCallImgData.some(item => item.Fstatus !== "delete")) ? (
                                <div className="RealData-btnContainer">
                                    <button className="RealData-save-button" onClick={handleUpload}>
                                        업로드
                                    </button>
                                </div>
                            ) : null}

                            <div className="research-method-title">
                                <label htmlFor="fileDiagram">
                                    <img id="imgDiagram" src={`${process.env.PUBLIC_URL}/btn_file_select.png`} alt="Diagram" />
                                </label>
                            </div>
                            <input
                                type="file"
                                id="fileDiagram"
                                name="dataSubmitFiles"
                                multiple
                                onChange={handleFileChange}
                                className="search-input"
                                placeholder="파일 이름"
                            />
                        </div>
                    </div>

                </section>

                <div className="RealData-save-btn-container">
                       <>
                           <button id="dmp_list" onClick={viewListHandler}>
                               목록
                           </button>
                           <button id="save-btn"  onClick={realDataSave} >
                               수정
                           </button>
                           <button id="dmp_list" onClick={TemporaryDatabtn} >
                               임시저장
                           </button>
                       </>
                </div>
            </div>
        </>
    )
}

export default DmpRealDataModify
