import React, { useEffect, useState } from "react";
import api from '../../api';

function DmpRealDataSend() {
    const [dataList, setDataList] = useState([]);
    const [dataCount, setDataCount] = useState(0); // 수정: 총 데이터 개수 상태값
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수

    const [btnPage, setBtnPage] = useState(1);

    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 수 (1-10, 11-20 등)
    const fetchData =  () => {
        try {
            api.get(`/api/data/datalist?kw&ps=${currentPage}&pl=${pageSize}`, {
                withCredentials: true
            }).then(res => {
                setDataCount(res.data.pages.Total); // 총 데이터 개수 설정
                setDataList(res.data.item); // 현재 페이지 데이터 목록
                setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);


    const handerbtn = (pageNumber, i) => {
        setCurrentPage(pageNumber);
        setBtnPage(i);

    }


    // 페이지네이션 렌더링 함수
    const renderPagination = () => {
        const startPage = Math.floor((btnPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            const pageNumber = (i - 1) * pageSize + 1;  // 각 페이지 그룹의 첫 페이지 계산 (1, 11, 21, 31...)
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === btnPage ? 'active' : ''}`}
                    onClick={() => handerbtn(pageNumber, i)}  // 페이지 버튼을 클릭 시 페이지 그룹의 첫 번째 페이지로 이동
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setBtnPage(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setBtnPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    const dataSendHandler = async (id) => {
        try {
            const response = await api.put(`/api/data/datasubmit?DtaSbmSeqNo=${id}`, {}, {
                withCredentials: true
            });

            // 성공 시 알림 및 데이터 다시 불러오기
            alert("데이터 제출이 완료 되었습니다.");
            fetchData();  // 데이터 목록 다시 불러오기
        } catch (error) {
            // 에러 처리
            console.error("데이터 제출 중 문제가 발생했습니다:", error);
            alert("데이터 제출 중 오류가 발생했습니다. 다시 시도해주세요.");
        }
    };


    const submitHandler02 = () => {
        alert("현재 제출 상태입니다.");
    }
    const completeHandler = () => {
        alert("현재 검토완료 상태입니다.");
    }
    const completeHandlerOpinion = () => {
        alert("현재 검토완료(의견있음) 상태입니다.");
    }

    console.log("dataListdataList",dataList);

    // const resultSendList = dataList.filter((row) => row.SubmitStatus !== '0')

    return (
        <>
            <div className="dmpReallist-container">
                <div className="dmpReallist-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg03.jpg`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="banner-Realtext">
                        <span className="intro-subName">데이터 제출</span>
                    </div>
                    <div className="dmpReallist-description">
                        <div className="dmpReallist-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 > 데이터 제출</span>
                        </div>
                    </div>
                </div>
                <div className="dmpReallist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text" placeholder="검색어를 입력해주세요." />
                </div>
                <div>
                    <div className="btn-Realcontainer">
                        <div>총 {dataCount}건</div>
                    </div>
                    <div className="dmpReallist-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="dmpReallist-title">
                            <th>과제번호</th>
                            <th>데이터셋/ 설명</th>
                            <th>연구 책임자</th>
                            <th>제출 상태</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataList
                            .map((row) => (
                            <tr key={row.DtaSbmSeqNo} className="dmpReallist-Data">
                                <td>{row.ProjNo}</td>
                                <td>{row.DtaSetNm} / {row.DtaDs}</td>
                                <td>{row.RspnEmpNm}</td>
                                <td>
                                    {row.SubmitStatus === '1' ? (
                                        <button className="dmpsend-submit01" onClick={() => dataSendHandler(row.DtaSbmSeqNo)}>데이터 제출하기</button>
                                    ) : row.SubmitStatus === '2' ? (
                                        <button className="dmpsend-submit02" onClick={submitHandler02}>제출</button>
                                    ) : row.SubmitStatus === '3' ? (
                                        <button className="dmpsend-complete" onClick={completeHandler}>검토완료</button>
                                    ) : row.SubmitStatus === '4' ? (
                                        <button className="dmpsend-complete-opinion" onClick={completeHandlerOpinion}>검토완료(의견있음)</button>
                                    ) : null }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* 페이지네이션 추가 */}
                    {renderPagination()}
                </div>
            </div>
        </>
    );
}

export default DmpRealDataSend;
